import React, { useCallback, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useMutation } from "react-query";
import CampaignsForm from "../../components/Campaigns/Form";

const CampaignsCreate = () => {
  const navigate = useNavigate();

  const { mutateAsync: createAsync } = useMutation("addCampaign");

  const [campaign, setCampaign] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    {}
  );
  const onSubmit = useCallback(
    async () => {
      await createAsync({ campaign });

      navigate("/campaigns");
    },
    [campaign, createAsync, navigate]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Create Campaign</h1>
          <hr />
          <Button variant="light" as={Link} to="/campaigns">
            Back to Campaigns
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <CampaignsForm
          campaign={campaign}
          setCampaign={setCampaign}
          onSubmit={onSubmit}
          submitText="Create Campaign"
        />
      </Container>
    </>
  );
};

export default CampaignsCreate;
