import React from "react";
import { useIsFetching, useIsMutating } from "react-query";

export const useIsApiInteracting = () => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  return isFetching > 0 || isMutating > 0;
};

export const DisabledOnInteracting = ({ children }) => {
  const isApiInteracting = useIsApiInteracting();

  return (
    <fieldset disabled={isApiInteracting}>
      {children}
    </fieldset>
  );
};
