import React, { useCallback, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import ButtonToggle from "../../components/Form/ButtonToggle/ButtonToggle";
import Loading from "../../components/Loading/Loading";
import { useAuthentication } from "../../hooks/useAuthentication";
import useGetTwitchUserVideos from "../../hooks/twitch/useGetTwitchUserVideos";

const UpdateUser = () => {
  const { twitchId } = useParams();
  const {
    currentUser,
    twitchId: currentUserTwitchId,
    isAdmin
  } = useAuthentication();
  const { data: { user } } = useQuery(
    {
      queryKey: ["user", twitchId],
      placeholderData: { user: {} }
    }
  );
  const { mutateAsync } = useMutation("updateUser");
  const { isLoading: isLoadingApiToken, mutateAsync: mutateApiToken } =
    useMutation("updateUserApiToken");

  const { twitchUserVideos } = useGetTwitchUserVideos(twitchId);

  const [sonyNpsso, setSonyNpsso] = useState("");

  const onToggleAdmin = useCallback(
    () =>
      mutateAsync({
        user: { twitchId: user.twitchId, isAdmin: !user.isAdmin }
      }),
    [mutateAsync, user.twitchId, user.isAdmin]
  );

  const onToggleBot = useCallback(
    () =>
      mutateAsync({
        user: { twitchId: user.twitchId, isBot: !user.isBot }
      }),
    [mutateAsync, user.twitchId, user.isBot]
  );

  const onSaveSonyNpsso = useCallback(
    () => {
      mutateAsync({
        user: { twitchId: user.twitchId, sonyNpsso: sonyNpsso }
      });

      setSonyNpsso("");
    },
    [mutateAsync, user.twitchId, sonyNpsso, setSonyNpsso]
  );

  const onUpdateApiTokenHandler = useCallback(
    () => mutateApiToken({ user }),
    [mutateApiToken, user]
  );

  const isCurrentUser = useMemo(
    () => twitchId === currentUserTwitchId,
    [currentUserTwitchId, twitchId]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Users / {user.displayName || twitchId}</h1>
          <hr />
          <Button variant="light" as={Link} to="/users">
            Back to Users
          </Button>
          {isAdmin && (
            <ButtonGroup className="float-right" >
              <Button
                variant="dark"
                as="a"
                href="https://ca.account.sony.com/api/v1/ssocookie"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Sony NPSSO
              </Button>
            </ButtonGroup>
          )}
        </Container>
      </Jumbotron>
      <Container>
        <Loading />
        <Row>
          <Col xs={12} lg={6}>
            <Image src={user.thumbnailUrl} fluid />
            <Table variant="dark" striped borderless responsive>
              <tbody>
                <tr>
                  <td>Display Name</td>
                  <td colSpan="2">
                    <a
                      className="text-secondary"
                      href={`https://www.twitch.tv/${user.displayName}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {user.displayName}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>Twitch ID</td>
                  <td colSpan="2">{twitchId}</td>
                </tr>
                <tr>
                  <td>Is Admin?</td>
                  <td colSpan="2">
                    <ButtonToggle
                      value={user.isAdmin}
                      onToggle={onToggleAdmin}
                      readOnly={!isAdmin}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Is Bot?</td>
                  <td colSpan="2">
                    <ButtonToggle
                      value={user.isBot}
                      onToggle={onToggleBot}
                      readOnly={!isAdmin || !user.isBot}
                    />
                  </td>
                </tr>
                {isCurrentUser && (
                  <tr>
                    <td>API Token</td>
                    <td>
                      {currentUser.apiUser?.apiToken || (
                        <Badge variant="warning">please regenerate</Badge>
                      )}
                    </td>
                    <td className="text-right">
                      <Button
                        variant="warning"
                        onClick={onUpdateApiTokenHandler}
                      >
                        <FontAwesomeIcon
                          icon={faRotate}
                          spin={isLoadingApiToken}
                        />
                        <span className="pl-1">Regenerate</span>
                      </Button>
                    </td>
                  </tr>
                )}
                {isAdmin && user.isBot && (
                  <tr>
                    <td>Sony Auth</td>
                    <td colSpan="2">
                      <InputGroup>
                        <Form.Control
                          placeholder="Sony NPSSO"
                          type="password"
                          value={user.sonyTokenData?.accessToken || sonyNpsso}
                          onChange={({ target }) => setSonyNpsso(target.value)}
                        />
                        <InputGroup.Append>
                          <Button variant="dark" onClick={onSaveSonyNpsso} disabled={!sonyNpsso}>
                            Sign-in to Sony PSN
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>Created At</td>
                  <td colSpan="2">
                    {user.createdAt && moment(user.createdAt).format("LLL")}
                  </td>
                </tr>
              </tbody>
            </Table>
            <hr />
            <h2>Twitch History</h2>
            <Table variant="dark" striped borderless responsive>
              <tbody>
                {twitchUserVideos?.map(video => (
                  <tr key={video.id}>
                    <td><Image src={video.getThumbnailUrl(512, 512)} fluid /></td>
                    <td>
                      <a
                        className="text-secondary"
                        href={video.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {video.title}
                      </a>
                    </td>
                    <td>{moment(video.creationDate).format("LLL")}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          {user.displayName && (
            <Col xs={12} lg={6}>
              <ReactTwitchEmbedVideo
                muted={true}
                theme="dark"
                width="100%"
                channel={user.displayName}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default UpdateUser;
