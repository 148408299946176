import React, { useCallback, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import MessagesForm from "../../components/Messages/Form";

const MessagesCreate = () => {
  const navigate = useNavigate();

  const { mutateAsync: createAsync } = useMutation("addMessage");

  const [message, setMessage] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    {}
  );

  const onSubmit = useCallback(
    async (message) => {

      await createAsync({ message });

      navigate("/messages");
    },
    [createAsync, navigate]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Create Messages</h1>
          <hr />
          <Button variant="light" as={Link} to="/messages">
            Back to Messages
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <MessagesForm message={message} setMessage={setMessage} onSubmit={onSubmit} submitText="Create Message" />
      </Container>
    </>
  );
};

export default MessagesCreate;
