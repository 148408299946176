import React, { useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastBody from "react-bootstrap/ToastBody";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import collect from "collect.js";
import styles from "./ErrorNotifications.module.scss";
import useStreamerMode from "../../hooks/useStreamerMode";

const ErrorNotifications = () => {
  const navigate = useNavigate();
  const { streamerMode } = useStreamerMode();

  const [errors, errorsReducer] = useReducer((accumulator, { type, value }) => {
    switch (type) {
      case "add":
        const copy = [...accumulator];
        const existingError = collect(copy).firstWhere("error", value);

        if (!existingError) {
          copy.push({ error: value, uuid: uuidv4(), show: true, count: 1 });
        } else {
          const index = copy.findIndex(
            ({ uuid }) => uuid === existingError.uuid
          );
          copy[index] = {
            ...existingError,
            show: true,
            count: existingError.count + 1
          };
        }

        return copy;

      case "remove":
        return accumulator.map(obj => {
          if (obj.uuid === value) {
            return { ...obj, show: false };
          }

          return obj;
        });

      default:
        return accumulator;
    }
  }, []);

  useEffect(() => {
    axios.interceptors.response.use(null, error => {
      if (error.response?.status === 401) {
        navigate("/401");
      } else if (error.response?.data?.message) {
        errorsReducer({ type: "add", value: error.response.data.message });
      } else {
        errorsReducer({ type: "add", value: error });
      }

      return Promise.reject(error);
    });
  }, [navigate, errorsReducer]);

  if (streamerMode) {
    return <div hidden />;
  }

  return (
    <div className={styles.wrapper}>
      {collect(errors).map(({ error, uuid, show, count }) => (
        <Toast
          key={uuid}
          show={show}
          onClose={() => errorsReducer({ type: "remove", value: uuid })}
          delay={8000}
          autohide
          animation
        >
          <ToastBody
            as={Alert}
            variant="danger"
            dismissible
            className={styles.toastBody}
            onClose={() => errorsReducer({ type: "remove", value: uuid })}
          >
            {count > 1 && (
              <Badge variant="warning" className="float-left" pill>
                {count}
              </Badge>
            )}
            <h5 className={styles.toastParagraph}>{error.toString()}</h5>
          </ToastBody>
        </Toast>
      ))}
    </div>
  );
};

export default ErrorNotifications;
