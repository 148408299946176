import React, { useCallback, useReducer } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useQuery, useMutation } from "react-query";
import EventForm from "../../components/Events/Form";

const EventsUpdate = () => {
  const navigate = useNavigate();
  const { name: eventName } = useParams();

  const { data } = useQuery(
    {
      queryKey: ["event", eventName],
      placeholderData: {},
      onSuccess: ({ event }) => setEvent(event)
    }
  );

  const { mutateAsync: updateAsync } = useMutation("updateEvent");

  const [event, setEvent] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    data.event || { groupNames: [], chatGroups: {} }
  );

  const onSubmit = useCallback(
    async () => {
      await updateAsync({ event });

      navigate("/events");
    },
    [event, updateAsync, navigate]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Create Event</h1>
          <hr />
          <Button variant="light" as={Link} to="/events">
            Back to Events
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <EventForm
          event={event}
          setEvent={setEvent}
          onSubmit={onSubmit}
          submitText="Update Event"
          persisted
        />
      </Container>
    </>
  );
};

export default EventsUpdate;
