import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Table from "../../components/Table/Table";
import TableWrapper from "../../components/Table/Wrapper";
import TableSearch from "../../components/Table/Search";

const ParticipantsIndex = () => {
  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Participants</h1>
        </Container>
      </Jumbotron>
      <Container>
        <TableWrapper
          columns={["email"]}
          dataKey="participant"
          primaryKeyName="twitchId"
          mustBeAdmin
        >
          <TableSearch />
          <Table showCreate showUpdate showDelete />
        </TableWrapper>
      </Container>
    </>
  );
};

export default ParticipantsIndex;
