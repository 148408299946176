import React, { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import collect from "collect.js";
import PSNLogo from "../../../assets/Playstation_logo_logotype_emblem_colour.png";
import Loading from "../../Loading/Loading";

const Search = ({ selectedPsnUser, onSelectPsnUser }) => {
  const [searchText, setSearchText] = useState("");

  const options = {
    cacheTime: false,
    enabled: false,
    retry: false,
  };

  const {
    data: searchData,
    refetch: searchRefetch,
    isLoading: isLoadingSearch,
  } = useQuery({
    queryKey: ["psn", "search", "username", searchText],
    ...options,
  });
  const {
    data: exactData,
    refetch: exactRefetch,
    isLoading: isLoadingExact,
  } = useQuery({
    queryKey: ["psn", "username", searchText],
    ...options,
  });

  const combinedResults = useMemo(
    () => collect(searchData?.domainResponses || [])
      .pluck("results")
      .flatten(1)
      .pluck("socialMetadata")
      .merge([exactData?.profile])
      .where()
      .unique(obj => obj.accountId),
    [searchData?.domainResponses, exactData?.profile],
  );

  return (
    <div>
      <fieldset disabled={isLoadingSearch || isLoadingExact}>
        <InputGroup className="overflow-hidden mb-3">
          <InputGroup.Append className="rounded-left">
            <InputGroup.Text>
              <Image
                src={PSNLogo}
                style={{ height: "-webkit-fill-available" }}
                width="25px"
              />
            </InputGroup.Text>
          </InputGroup.Append>
          <Form.Control
            value={searchText}
            placeholder="Search PSN by username"
            onChange={({ target }) => setSearchText(target.value)}
          />
          <InputGroup.Append>
            <Button onClick={() => exactRefetch()}>
              Exact Match
            </Button>
            <Button onClick={() => searchRefetch()}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
        {(isLoadingSearch || isLoadingExact) && <Loading />}
        <Table variant="dark" striped borderless responsive>
          <tbody>
            {combinedResults?.map(
              obj => (
                <tr key={obj.accountId}>
                  <td>
                    <Image
                      src={obj.avatarUrl || collect(obj.avatarUrls).pluck("avatarUrl").firstWhere()}
                      height={50}
                    />
                    <span className="pl-2">
                      {obj.onlineId}
                    </span>
                  </td>
                  <td>{obj.accountId}</td>
                  <td className="text-right">
                    <Button
                      variant={selectedPsnUser?.accountId === obj.accountId ? "success" : "dark"}
                      onClick={() => onSelectPsnUser(obj)}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      </fieldset>
    </div>
  );
}

export default Search;
