import React, { useEffect, useMemo, useState } from "react";
import { defaults } from "chart.js";
import 'chart.js/auto';
import { Chart } from "react-chartjs-2";
import collect from "collect.js";
import randomFlatColors from "random-flat-colors";
import styles from "./Bar.module.scss";
import NoData from "../../../NoData/NoData";

const ChartMessageAnswersBar = ({
  message,
  fontFamily,
  roundness,
  colours,
  showChartWithNoData,
  ...props
}) => {
  const answers = useMemo(() => collect(message.answers || []), [message.answers]);

  const answerCounts = useMemo(() =>
    answers.map((_, index) => message[`answerCount${index + 1}`] || 0).toArray(),
    [answers, message]
  );

  useEffect(() => {
    const previousFontFamily = defaults.font.family;
    defaults.font.family = fontFamily;

    return () => defaults.font.family = previousFontFamily;
  }, [fontFamily]);

  const [containerElementRef, setContainerElementRef] = useState(undefined);
  const titleFont = useMemo(() => ({
    family: fontFamily,
    size: "48px",
  }), [fontFamily]);
  const messageWithNewLines = useMemo(() => {
    const screenWidth = containerElementRef?.offsetWidth;
    const text = message.message || "";

    const words = text.split(' ');
    if (words.length === 0 || !screenWidth) {
      return [text];
    }

    // re-use canvas object for better performance
    const canvas = document.createElement('canvas');

    const getTextWidth = (text) => {
      const context = canvas.getContext('2d');

      if (!context) {
        return 0;
      }

      context.font = [titleFont.size, titleFont.family].join(' ');

      return context.measureText(text).width;
    };

    return words
      .reduce((accumulator, currentValue) => {
        const lines = accumulator.split('\\N');
        const lastLine = lines[lines.length - 1];
        const textWidth = getTextWidth(`${lastLine} ${currentValue}`.trim());

        if (textWidth > screenWidth) {
          return accumulator + '\\N' + currentValue;
        }

        return accumulator + ' ' + currentValue;
      }, '')
      .trim()
      .split('\\N')
      .filter(Boolean);
  }, [message.message, titleFont, containerElementRef?.offsetWidth]);

  const data = useMemo(
    () => ({
      labels: answers.pluck("text").toArray(),
      datasets: [
        {
          label: "Votes",
          backgroundColor: answerCounts.map(
            (_, index) => colours?.[index] || answers.get(index)?.colour || randomFlatColors()
          ),
          borderRadius: { topLeft: roundness, topRight: roundness },
          borderWidth: 1,
          data: answerCounts,
        }
      ]
    }),
    [answers, answerCounts, roundness, colours]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltips: { enabled: false },
        title: {
          color: "white",
          display: messageWithNewLines.length > 0,
          fullSize: true,
          font: titleFont,
          text: messageWithNewLines,
          wrap: true
        }
      },
      scales: {
        xAxis: {
          stacked: true
        },
        yAxis: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            precision: 0
          }
        }
      }
    }),
    [messageWithNewLines, titleFont]
  );

  if (!showChartWithNoData && collect(answerCounts).sum() === 0) {
    return <NoData />;
  }

  return (
    <div className={styles.barContainer} {...props} ref={setContainerElementRef}>
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default ChartMessageAnswersBar;
