import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import DataContext from "./Context";

const Search = () => {
  const { setSearchString, columns } = useContext(DataContext) || {};

  const [searchTerm = "_"] = columns || ["_"];

  return (
    <InputGroup className="overflow-hidden mb-4">
      <Form.Control placeholder={`Search by ${searchTerm}`} onChange={({ target }) => setSearchString(target.value)} />
      <InputGroup.Append>
        <Button disabled>
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};

export default Search;
