import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkull } from "@fortawesome/free-solid-svg-icons";
import styles from "./NoData.module.scss";

const NoData = ({ children = "Nothing to show!" }) => {
  return (
    <h2 className={styles.wrapper}>
      <FontAwesomeIcon icon={faSkull} size="1x" />
      <span className="pl-2">{children}</span>
    </h2>
  );
};

export default NoData;
