import React, { useState, useMemo, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSave, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useQuery, useMutation } from "react-query";
import collect from "collect.js";
import NoData from "../../../../components/NoData/NoData";
import { SortableTableHeader } from "../../../../components/Table/Table";
import styles from "../../../../components/Table/Table.module.scss";
import Loading from "../../../../components/Loading/Loading";

const AchievementOrTrophy = ({ achievementOrTrophy: { id, game, title, platform, weight }, eventName }) => {
  const [newWeight, setNewWeight] = useState(null);

  const canSave = useMemo(
    () => newWeight !== null && newWeight !== weight,
    [newWeight, weight],
  );

  const { isLoading, mutateAsync: updateAsync } = useMutation("updateAchievementOrTrophy");

  const updateAchievementOrTrophy = useCallback(
    async (id) => {
      await updateAsync({ achievementOrTrophy: { id, eventName, weight: newWeight } });

      setNewWeight(null);
    },
    [updateAsync, eventName, newWeight],
  );

  return (
    <tr key={id}>
      <td>{game}</td>
      <td>{title}</td>
      <td className="text-uppercase">{platform}</td>
      <td>
        <InputGroup className="overflow-hidden">
          <Form.Control
            type="number"
            onChange={({ target }) => setNewWeight(target.value)}
            defaultValue={weight}
            min="1"
            required
          />
          <Button onClick={() => updateAchievementOrTrophy(id)} disabled={!canSave}>
            {
              isLoading ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : (
                <FontAwesomeIcon icon={faSave} />
              )
            }
          </Button>
        </InputGroup>
      </td>
    </tr>
  );
};

const EventsView = () => {
  const { name: eventName } = useParams();

  const [searchString, setSearchString] = useState("");
  const [sortByKey, setSortByKey] = useState("title");
  const [isSortAscending, setIsSortAscending] = useState(true);

  const sortData = useCallback(
    key => {
      const keyLowerCase = key.toLowerCase();

      if (sortByKey === keyLowerCase) {
        setIsSortAscending(value => !value);
      }

      setSortByKey(keyLowerCase);
    },
    [sortByKey],
  );

  const { data: { event } = {} } = useQuery(["event", eventName]);
  const { data: { achievementsOrTrophies } = {} } = useQuery(["event", eventName, "achievementsOrTrophies"]);

  const achievementsOrTrophiesCollection = useMemo(
    () => collect(achievementsOrTrophies)
      .map(({ id, title, achievementOrTrophy, platform, weight = 1 }) => ({
        id,
        game: title.name || title.trophyTitleName,
        title: achievementOrTrophy.name || achievementOrTrophy.titleTrophy?.trophyName,
        platform,
        weight,
      })),
    [achievementsOrTrophies],
  );

  const achievementsOrTrophiesCollectionFilteredAndSorted = useMemo(
    () => achievementsOrTrophiesCollection
      .when(searchString, items => items.filter(({ game, title, platform }) =>
        [game, title, platform]
          .join()
          .toLocaleLowerCase()
          .includes(searchString.toLocaleLowerCase()),
      ))
      .when(isSortAscending, items => items.sortBy(sortByKey))
      .when(!isSortAscending, items => items.sortByDesc(sortByKey)),
    [achievementsOrTrophiesCollection, searchString, isSortAscending, sortByKey],
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Events / {event?.name || eventName} / Achievements or Trophies</h1>
          <hr />
          <Button variant="light" as={Link} to={`/events/${encodeURIComponent(eventName)}`}>
            Back to Event
          </Button>
        </Container>
      </Jumbotron>
      <Loading />
      <Container>
        {achievementsOrTrophiesCollection.isEmpty() ? (
          <NoData />
        ) : (
          <>
            <InputGroup className="overflow-hidden mb-4">
              <Form.Control placeholder="Search for keywords..." onChange={({ target }) => setSearchString(target.value)} />
              <InputGroup.Append>
                <Button disabled>
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Table variant="dark" striped borderless responsive>
              <thead className={styles.thead}>
                <tr>
                  <SortableTableHeader name="Game" onSortData={sortData} />
                  <SortableTableHeader name="Title" onSortData={sortData} />
                  <SortableTableHeader name="Platform" onSortData={sortData} />
                  <SortableTableHeader name="Points" onSortData={sortData} />
                </tr>
              </thead>
              <tbody>
                {achievementsOrTrophiesCollectionFilteredAndSorted.map((obj) => (
                  <AchievementOrTrophy key={obj.id} achievementOrTrophy={obj} eventName={eventName} />
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Container>
    </>
  );
};

export default EventsView;
