import { QueryClient } from "react-query";
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { createAsyncStoragePersistor } from 'react-query/createAsyncStoragePersistor-experimental'
import axios from "axios";
import setupUsersMutations from "./mutations/users";
import setupCampaignsMutations from "./mutations/campaigns";
import setupEventsMutations from "./mutations/events";
import setupGroupsMutations from "./mutations/groups";
import setupMessages from "./mutations/messages";
import setupParticipants from "./mutations/participants";
import setupAchievementsOrTrophies from "./mutations/achievementsOrTrophies";

const setup = () => {
  const useCache = true;

  axios.defaults.baseURL = process.env.REACT_APP_API_ROOT;

  const queryClient = new QueryClient();

  const queryFn = async ({ queryKey }) => {
    const url = queryKey
      .filter(e => typeof e === "string" && e)
      .map(encodeURIComponent)
      .join("/");
    const { params } = queryKey.filter(e => typeof e === "object" && e)[0] || {};

    console.debug("Querying API...", { url, params });
    const { data } = await axios.get("/" + url, { params });
    console.debug("API returned!", { data });

    return data;
  };

  setupUsersMutations(queryClient);
  setupCampaignsMutations(queryClient);
  setupEventsMutations(queryClient);
  setupGroupsMutations(queryClient);
  setupMessages(queryClient, queryFn);
  setupParticipants(queryClient);
  setupAchievementsOrTrophies(queryClient);

  queryClient.setDefaultOptions({
    queries: {
      staleTime: 10 * 60 * 1000,
      cacheTime: useCache ? 1000 * 60 * 60 * 24 : 0,
      queryFn,
    },
  });

  const localStoragePersistor = createAsyncStoragePersistor({ storage: window.localStorage });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor
  });

  return queryClient;
};

export default setup;
