import { useState, useEffect, useCallback } from "react";
import useTwitchApi from "./useTwitchApi";

const useGetTwitchUsers = userTwitchIds => {
  const { twitchApiClient } = useTwitchApi();

  const [isLoading, setIsLoading] = useState(false);
  const [twitchUsers, setTwitchUsers] = useState([]);

  const getUsersAsync = useCallback(
    async userTwitchIds => {
      if (isLoading || !userTwitchIds) {
        return;
      }

      console.debug("Getting twitch users by IDs", { userTwitchIds });
      const users = await twitchApiClient.users.getUsersByIds(userTwitchIds);
      console.debug("Twitch users found!", { users });

      setTwitchUsers(users || []);
      setIsLoading(false);
    },
    [twitchApiClient, isLoading, setIsLoading, setTwitchUsers]
  );

  useEffect(() => {
    if (isLoading || !userTwitchIds || userTwitchIds.length === 0) {
      return;
    }

    getUsersAsync(userTwitchIds);
  }, [isLoading, getUsersAsync, userTwitchIds]);

  return {
    isLoading,
    getUsersAsync,
    twitchUsers
  };
};

export default useGetTwitchUsers;
