import axios from "axios";

const setup = queryClient => {
  const onSuccess = () => {
    queryClient.invalidateQueries("event");
  };

  queryClient.setMutationDefaults("addEvent", {
    mutationFn: async ({ event }) => {
      const { data } = await axios.post("event", event);

      onSuccess();

      return data;
    }
  });

  queryClient.setMutationDefaults("updateEvent", {
    mutationFn: async ({ event: { name, ...event } }) => {
      const { data } = await axios.put(`event/${encodeURIComponent(name)}`, {
        name,
        ...event
      });

      onSuccess();
      queryClient.invalidateQueries(`event/${name}`);

      return data;
    }
  });

  queryClient.setMutationDefaults("deleteEvent", {
    mutationFn: async ({ name }) => {
      const { data } = await axios.delete(`event/${encodeURIComponent(name)}`);

      onSuccess();
      queryClient.invalidateQueries(`event/${name}`);

      return data;
    }
  });

  return queryClient;
};

export default setup;
