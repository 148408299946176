import React, { useReducer, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import ParticipantsForm from "../../components/Participants/Form";

const ParticipantsUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data } = useQuery(
    {
      queryKey: ["participant", id],
      placeholderData: {},
      onSuccess: (data) => setParticipant(data.participant)
    }
  );

  const { mutateAsync } = useMutation("updateParticipant");

  const [participant, setParticipant] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    data.participant || {}
  );

  const onSubmit = useCallback(async () => {
    await mutateAsync({ participant });

    navigate("/participants");
  }, [mutateAsync, participant, navigate]);

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Participant / {id}</h1>
          <hr />
          <Button variant="light" as={Link} to="/participants">
            Back to Participants
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <ParticipantsForm
          participant={participant}
          setParticipant={setParticipant}
          onSubmit={onSubmit}
          submitText="Update Participant"
        />
      </Container>
    </>
  );
};

export default ParticipantsUpdate;
