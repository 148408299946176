import axios from "axios";

const setup = queryClient => {
  queryClient.setMutationDefaults("updateAchievementOrTrophy", {
    mutationFn: async ({ achievementOrTrophy: { eventName, id, ...achievementOrTrophy } }) => {
      const { data } = await axios.put(
        `event/${encodeURIComponent(eventName)}/achievementsOrTrophies/${encodeURIComponent(id)}`,
        achievementOrTrophy,
      );

      queryClient.invalidateQueries(["event", eventName, "achievementsOrTrophies"]);

      return data;
    }
  });

  return queryClient;
};

export default setup;
