import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import Button from "react-bootstrap/Button";

const ButtonToggle = ({ value, onToggle, readOnly }) => (
  <Button
    variant={value ? "success" : "light"}
    onClick={onToggle}
    disabled={readOnly}
  >
    <FontAwesomeIcon icon={value ? faCheckCircle : faTimesCircle} />
    <span className="pl-1 text-capitalize">{(value || false).toString()}</span>
  </Button>
);

export default ButtonToggle;
