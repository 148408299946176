import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useQuery } from "react-query";
import collect from "collect.js";
import Table from "../../components/Table/Table";
import TableWrapper from "../../components/Table/Wrapper";
import TableSearch from "../../components/Table/Search";
import { useAuthentication } from "../../hooks/useAuthentication";

const UsersIndex = () => {
  const { REACT_APP_API_ROOT } = process.env;

  const { isAdmin } = useAuthentication();

  const { data: { users }, isLoading } = useQuery("user", { placeholderData: { users: [] } });

  const hasBot = useMemo(() => collect(users).firstWhere("isBot"), [users]);

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Users</h1>
          <hr />
          <ButtonGroup>
            <Button variant="light" as={Link} to="/users/create">
              <span>Allow Twitch User</span>
            </Button>
            {isAdmin && !hasBot && !isLoading && (
              <Button
                variant="warning"
                as="a"
                href={`${REACT_APP_API_ROOT}/auth/twitch/bot`}
              >
                <FontAwesomeIcon icon={faRobot} />
                <span className="pl-1">Register Self as Bot</span>
              </Button>
            )}
          </ButtonGroup>
        </Container>
      </Jumbotron>
      <Container>
        <TableWrapper
          columns={["displayName", "isAdmin", "isBot", "createdAt"]}
          dataKey="user"
          primaryKeyName="twitchId"
        >
          <TableSearch />
          <Table showDelete showUpdate />
        </TableWrapper>
      </Container>
    </>
  );
};

export default UsersIndex;
