import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import moment from 'moment';
import collect from "collect.js";
import useStreamerMode, { StreamerModeWidget } from "../../../../hooks/useStreamerMode";
import ChartMessageAnswersBar from "../../../../components/Messages/Charts/Answers/Bar";
import NoData from "../../../../components/NoData/NoData";

const EventMessagesView = () => {
  const { name: eventName } = useParams();
  const { streamerMode, refreshRateMs, fontFamily, roundness } = useStreamerMode();

  const { data } = useQuery({
    queryKey: ["message", { params: { expired: false } }],
    refetchInterval: streamerMode ? refreshRateMs : 60 * 1000,
    placeholderData: {}
  });

  const message = useMemo(
    () => {
      const now = moment();
      const isBetweenNow = (start, end) => now.isBetween(start, end);

      return collect(data.messages || [])
        .where("eventName", "===", eventName)
        .where("answers")
        .filter(({ sendMessageAt, sendMessageUntil }) => isBetweenNow(sendMessageAt, sendMessageUntil))
        .first();
    },
    [data.messages, eventName]
  );

  return (
    <>
      <StreamerModeWidget showRoundness />
      {message ? (
        <ChartMessageAnswersBar
          message={message}
          style={{ height: "100vh" }}
          fontFamily={fontFamily}
          roundness={roundness}
          showChartWithNoData
        />
      ) : (
        <NoData />
      )}
    </>
  );
};

export default EventMessagesView;
