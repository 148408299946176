import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import FormLabel from "react-bootstrap/FormLabel";
import pluralize from "pluralize";
import NoData from "../../NoData/NoData";
import FormBadge from "../Badge/Badge";

const MutliSelectButtons = ({
  label,
  options,
  value,
  setValue,
  name,
  optional,
  required
}) => {
  const handleOnClick = useCallback(
    option => {
      console.debug("Checking if option already exists", { option, value });

      if (value.includes(option)) {
        console.debug("Removing new value", { option, value });
        setValue(value.filter(obj => obj !== option));

        return;
      }

      console.debug("Adding new value", { option, value });
      setValue([...value, option]);
    },
    [setValue, value]
  );

  return (
    <>
      <FormLabel className="w-100">
        <span>{label}</span>
        {(optional || required) && (<FormBadge populated={(value).filter(Boolean).length > 0} required={required} />)}
        <hr className="bg-light" />
      </FormLabel>
      <div>
        {options.length === 0 && (
          <NoData>You have no {pluralize(name)}!</NoData>
        )}
        {options.map(option => (
          <Button
            className="mr-2 mb-2"
            key={option}
            variant={value.includes(option) ? "primary" : "dark"}
            onClick={() => handleOnClick(option)}
          >
            {option}
          </Button>
        ))}
      </div>
    </>
  );
};

export default MutliSelectButtons;
