import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import collect from "collect.js";
import { useQuery } from "react-query";
import Loading from "../Loading/Loading";
import MutliSelectButtons from "../Form/MultiSelectButtons/MultiSelectButtons";

const GroupsForm = ({ group, setGroup, onSubmit, submitText }) => {
  const { data: { campaigns = [] } = {} } = useQuery({ queryKey: "campaign" });
  const { data: { users = [] } = {} } = useQuery({ queryKey: "user" });

  const onSubmitForm = useCallback(
    async formEvent => {
      formEvent.preventDefault();
      formEvent.stopPropagation();

      onSubmit(group)
    },
    [group, onSubmit]
  );

  return (
    <>
      <Loading />
      <Form onSubmit={onSubmitForm}>
        <Form.Row>
          <Form.Group as={Col} controlId="groupName">
            <Form.Label>Group Name</Form.Label>
            <Form.Control
              placeholder="Enter Group Name"
              onChange={({ target }) => setGroup({ name: target.value })}
              defaultValue={group.name}
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="campaignNames">
            <MutliSelectButtons
              name="campaign"
              label="Campaigns"
              options={(campaigns || []).map(({ name }) => name)}
              value={group.campaignNames || []}
              setValue={campaignNames => setGroup({ campaignNames })}
              optional
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="twitchIds">
            <MutliSelectButtons
              name="user"
              label="Users"
              options={collect(users).pluck("displayName").toArray()}
              value={(group.twitchIds || []).map(
                twitchId =>
                  collect(users)
                    .firstWhere("twitchId", "===", twitchId)
                    ?.displayName
              )}
              setValue={displayNames =>
                setGroup({
                  twitchIds: displayNames.map(
                    name =>
                      collect(users).firstWhere("displayName", "===", name)
                        .twitchId
                  )
                })
              }
              optional
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col>
            <Button variant="primary" type="submit">
              {submitText}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </>
  );
};

export default GroupsForm;
