import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Table from "../../components/Table/Table";
import TableWrapper from "../../components/Table/Wrapper";
import TableSearch from "../../components/Table/Search";

const GroupsIndex = () => {
  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Groups</h1>
          <hr />
          <Button variant="light" as={Link} to="/groups/create">
            Create New Group
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <TableWrapper
          columns={["name", "campaignNames", "twitchDisplayNames"]}
          primaryKeyName="name"
          dataKey="group"
        >
          <TableSearch />
          <Table showCreate showUpdate showDelete />
        </TableWrapper>
      </Container>
    </>
  );
};

export default GroupsIndex;
