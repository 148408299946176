import axios from "axios";

const setup = queryClient => {
  const onSuccess = () => {
    queryClient.invalidateQueries("group");
  };

  queryClient.setMutationDefaults("addGroup", {
    mutationFn: async ({ group }) => {
      const { data } = await axios.post("group", group);

      onSuccess();

      return data;
    }
  });

  queryClient.setMutationDefaults("updateGroup", {
    mutationFn: async ({ group: { name, ...group } }) => {
      const { data } = await axios.put(`group/${encodeURIComponent(name)}`, {
        name,
        ...group
      });

      onSuccess();
      queryClient.invalidateQueries(`group/${name}`);

      return data;
    }
  });

  queryClient.setMutationDefaults("deleteGroup", {
    mutationFn: async ({ name }) => {
      const { data } = await axios.delete(`group/${encodeURIComponent(name)}`);

      onSuccess();
      queryClient.invalidateQueries(`group/${name}`);

      return data;
    }
  });

  return queryClient;
};

export default setup;
