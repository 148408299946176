import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Index.module.scss";

const ChatBot = () => {
  const { search } = useLocation();

  const code = useMemo(() => new URLSearchParams(search).get("code"), [search]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        {code ? (
          <span className="text-primary">Welcome aboard!</span>
        ) : (
          <span className="text-danger">Unauthorised</span>
        )}
      </h1>
      <hr />
      <div>
        {code ? (
          <h3>
            You can safely close this tab.
          </h3>
        ) : (
          <h3 className="text-danger">
            Authentication problem, please try the URL from your chat bot again!
          </h3>
        )}
      </div>
    </div>
  );
};

export default ChatBot;
