import React, { useReducer, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import GroupsForm from "../../components/Groups/Form";

const GroupsUpdate = () => {
  const navigate = useNavigate();
  const { name: groupName } = useParams();

  const { data } = useQuery(
    {
      queryKey: ["group", groupName],
      placeholderData: {},
      onSuccess: (data) => setGroup(data.group)
    }
  );

  const { mutateAsync: updateGroup } = useMutation("updateGroup");

  const [group, setGroup] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    data.group || {}
  );

  const onSubmit = useCallback(async () => {
    await updateGroup({ group });

    navigate("/groups");
  }, [updateGroup, group, navigate]);

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Groups / {groupName}</h1>
          <hr />
          <Button variant="light" as={Link} to="/groups">
            Back to Groups
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <GroupsForm group={group} setGroup={setGroup} onSubmit={onSubmit} submitText="Update Group" />
      </Container>
    </>
  );
};

export default GroupsUpdate;
