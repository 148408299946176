import React, { useCallback, useReducer } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useQuery, useMutation } from "react-query";
import CampaignsForm from "../../components/Campaigns/Form";

const CampaignsUpdate = () => {
  const navigate = useNavigate();
  const { name: campaignName } = useParams();

  const { data } = useQuery(
    {
      queryKey: ["campaign", campaignName],
      placeholderData: {},
      onSuccess: (data) => setCampaign(data.campaign)
    }
  );

  const { mutateAsync: updateAsync } = useMutation("updateCampaign");

  const [campaign, setCampaign] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    data.campaign || {}
  );
  const onSubmit = useCallback(
    async () => {
      await updateAsync({ campaign });

      navigate("/campaigns");
    },
    [campaign, updateAsync, navigate]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Create Campaign</h1>
          <hr />
          <Button variant="light" as={Link} to="/campaigns">
            Back to Campaigns
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <CampaignsForm
          campaign={campaign}
          setCampaign={setCampaign}
          onSubmit={onSubmit}
          submitText="Update Campaign"
          persisted
        />
      </Container>
    </>
  );
};

export default CampaignsUpdate;
