import React, { useMemo, useState } from "react";
import { useQuery, useMutation } from "react-query";
import collect from "collect.js";
import capitalize from "capitalize";
import pluralize from "pluralize";
import DataContext from "./Context";
import Loading from "../Loading/Loading";
import Unauthorised from "../Unauthorised/Unauthorised";
import { useAuthentication } from "../../hooks/useAuthentication";

const DataWrapper = ({ columns, dataKey, dataParams, primaryKeyName, mustBeAdmin, children }) => {
  const { currentUser } = useAuthentication();

  const { data } = useQuery([dataKey, { params: dataParams }], { placeholderData: {} });
  const { mutateAsync: deleteAsync } = useMutation(
    `delete${capitalize(dataKey)}`
  );

  const [searchString, setSearchString] = useState("");

  const [searchTerm] = columns || ["_"];

  const sortedAndFilteredData = useMemo(
    () => collect(data[`${pluralize(dataKey.replace("_", "-"))}`] || [])
      .filter(({ [searchTerm]: searchValue }) => !!new RegExp(searchString, "ui").exec(searchValue)),
    [data, dataKey, searchTerm, searchString]
  );

  const dataContextProviderValue = {
    data: sortedAndFilteredData,
    searchString,
    setSearchString,
    deleteAsync,
    dataKey,
    columns,
    primaryKeyName
  };

  if (mustBeAdmin && !currentUser?.apiUser?.isAdmin) {
    return (
      <Unauthorised className="" />
    );
  }

  return (
    <DataContext.Provider value={dataContextProviderValue}>
      <>
        <Loading />
        {children && (
          <div className="mb-4">
            {typeof children === "function" ? children(dataContextProviderValue) : children}
          </div>
        )}
      </>
    </DataContext.Provider>
  );
};

export default DataWrapper;
