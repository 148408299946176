import React, { useState } from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import DocsIndex from "../components/Docs/Index";
import { useAuthentication } from "../hooks/useAuthentication";
import Search from "../components/Table/Search";
import SearchContext from "../components/Table/Context";

const Index = () => {
  const { currentUser } = useAuthentication();

  const [searchString, setSearchString] = useState("");
  const searchContextProviderValue = { setSearchString, columns: [] };

  return (
    <>
      <Jumbotron className="bg-dark">
        <Container>
          <h1 className="pb-4">Carper Creative / Twitch Bot</h1>
          <div>Welcome {currentUser?.twitchUser?.displayName}, let's get you started!</div>
          <hr />
          <SearchContext.Provider value={searchContextProviderValue}>
            <Search />
          </SearchContext.Provider>
        </Container>
      </Jumbotron>
      <Container>
        <DocsIndex searchString={searchString} />
      </Container>
    </>
  );
}

export default Index;
