import axios from "axios";

const setup = queryClient => {
  const onSuccess = () => {
    queryClient.invalidateQueries("campaign");
  };

  queryClient.setMutationDefaults("addCampaign", {
    mutationFn: async ({ campaign }) => {
      const { data } = await axios.post("campaign", campaign);

      onSuccess();

      return data;
    }
  });

  queryClient.setMutationDefaults("updateCampaign", {
    mutationFn: async ({ campaign: { name, ...campaign } }) => {
      const { data } = await axios.put(`campaign/${encodeURIComponent(name)}`, {
        name,
        ...campaign
      });

      onSuccess();
      queryClient.invalidateQueries(`campaign/${name}`);

      return data;
    }
  });

  queryClient.setMutationDefaults("deleteCampaign", {
    mutationFn: async ({ name }) => {
      const { data } = await axios.delete(`campaign/${encodeURIComponent(name)}`);

      onSuccess();
      queryClient.invalidateQueries(`campaign/${name}`);

      return data;
    }
  });

  return queryClient;
};

export default setup;
