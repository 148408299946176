import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import setupQueryClient from "./scripts/react-query/setupQueryClient";
import styles from "./App.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import AuthenticationProvider from "./hooks/useAuthentication";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Index from "./pages/Index";
import ErrorNotifications from "./components/ErrorNotifications/ErrorNotifications";
import Error401 from "./pages/Error/401/Index";
import SignIn from "./pages/Public/SignIn";
import ChatBot from "./pages/Public/ChatBot/Index";
import UsersIndex from "./pages/Users/Index";
import UsersCreate from "./pages/Users/Create";
import UsersUpdate from "./pages/Users/Update";
import CampaignsIndex from "./pages/Campaigns/Index";
import CampaignsCreate from "./pages/Campaigns/Create";
import CampaignsUpdate from "./pages/Campaigns/Update";
import EventsIndex from "./pages/Events/Index";
import EventsCreate from "./pages/Events/Create";
import EventsView from "./pages/Events/View/View";
import EventsChatGroupsView from "./pages/Events/View/ChatGroups/View";
import EventsMessagesView from "./pages/Events/View/Messages/View";
import EventsAchievementsTrophiesIndex from "./pages/Events/View/AchievementsOrTrophies/Index";
import EventsUpdate from "./pages/Events/Update";
import Participants from "./pages/Participants/Index";
import ParticipantsCreate from "./pages/Participants/Create";
import ParticipantsUpdate from "./pages/Participants/Update";
import GroupsIndex from "./pages/Groups/Index";
import GroupsCreate from "./pages/Groups/Create";
import GroupsUpdate from "./pages/Groups/Update";
import MessagesIndex from "./pages/Messages/Index";
import MessagesCreate from "./pages/Messages/Create";
import MessagesView from "./pages/Messages/View";
import MessagesUpdate from "./pages/Messages/Update";

const queryClient = setupQueryClient();

const App = () => {
  const isStreamerMode = window.location.search.includes('streamerMode=true') &&
    window.location.search.includes('hideWidget=true');

  return (
    <div className={styles.app} style={{ backgroundColor: isStreamerMode ? 'transparent' : undefined }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthenticationProvider>
            <Header />
            <ErrorNotifications />
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/401" element={<Error401 />} />
              <Route path="/public/sign_in" element={<SignIn />} />
              <Route path="/public/chat_bot" element={<ChatBot />} />
              <Route path="/users" element={<UsersIndex />} />
              <Route path="/users/create" element={<UsersCreate />} />
              <Route path="/users/:twitchId/update" element={<UsersUpdate />} />
              <Route path="/campaigns" element={<CampaignsIndex />} />
              <Route path="/campaigns/create" element={<CampaignsCreate />} />
              <Route path="/campaigns/:name/update" element={<CampaignsUpdate />} />
              <Route path="/events" element={<EventsIndex />} />
              <Route path="/events/create" element={<EventsCreate />} />
              <Route path="/events/:name" element={<EventsView />} />
              <Route path="/events/:name/chat-groups" element={<EventsChatGroupsView />} />
              <Route path="/events/:name/messages" element={<EventsMessagesView />} />
              <Route path="/events/:name/update" element={<EventsUpdate />} />
              <Route path="/events/:name/achievementsOrTrophies" element={<EventsAchievementsTrophiesIndex />} />
              <Route path="/participants" element={<Participants />} />
              <Route path="/participants/create" element={<ParticipantsCreate />} />
              <Route path="/participants/:id/update" element={<ParticipantsUpdate />} />
              <Route path="/groups" element={<GroupsIndex />} />
              <Route path="/groups/create" element={<GroupsCreate />} />
              <Route path="/groups/:name/update" element={<GroupsUpdate />} />
              <Route path="/messages" element={<MessagesIndex />} />
              <Route path="/messages/create" element={<MessagesCreate />} />
              <Route path="/messages/:messageId" element={<MessagesView />} />
              <Route path="/messages/:messageId/update" element={<MessagesUpdate />} />
            </Routes>
            <Footer />
          </AuthenticationProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
};

export default App;
