import axios from "axios";
import collect from "collect.js";

const setup = (queryClient, queryFn) => {
  const onSuccess = () => {
    queryClient.invalidateQueries("message");
  };

  queryClient.setMutationDefaults("addMessage", {
    mutationFn: async ({ message }) => {
      const { data } = await axios.post("message", message);

      onSuccess();

      return data;
    }
  });

  queryClient.setMutationDefaults("updateMessage", {
    mutationFn: async ({ message: { id, ...message } }) => {
      const { data } = await axios.put(`message/${id}`, {
        id,
        ...message
      });

      onSuccess();
      queryClient.invalidateQueries(`message/${id}`);

      return data;
    }
  });

  queryClient.setMutationDefaults("deleteMessage", {
    mutationFn: async ({ id }) => {
      const { data } = await axios.delete(`message/${id}`);

      onSuccess();
      queryClient.invalidateQueries(`message/${id}`);

      return data;
    }
  });

  queryClient.setMutationDefaults("sendMessageNow", {
    mutationFn: async ({ id }) => {
      await axios.post(`message/${id}/now`);
    }
  });

  queryClient.setQueryDefaults(["message"], {
    queryFn: async ({ queryKey, ...args }) => {
      const [, messageId] = queryKey;

      if (typeof messageId !== "object") {
        console.debug("Attempting to find message from cache...", { messageId });

        const messageCache = collect(queryClient.getQueriesData({
          queryKey: ["message"],
          predicate: ({ queryKey }) => queryKey[1] === messageId || typeof queryKey[1] === 'object',
        }));
        const message = messageCache
          .dump()
          .map(queryData => queryData[1])
          .filter(Boolean)
          .flatMap(({ messages, ...message }) => messages ? messages : message)
          .dump()
          .firstWhere("id", "===", messageId);

        if (message) {
          console.debug("Returning message from cache...", { message });
          return { message };
        }
      }

      return queryFn({ queryKey, ...args });
    }
  });

  return queryClient;
};

export default setup;
