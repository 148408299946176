import axios from "axios";

const setup = queryClient => {
  const onSuccess = () => {
    queryClient.invalidateQueries("participant");
  };

  queryClient.setMutationDefaults("addParticipant", {
    mutationFn: async ({ participant }) => {
      const { data } = await axios.post("participant", participant);

      onSuccess();

      return data;
    }
  });

  queryClient.setMutationDefaults("updateParticipant", {
    mutationFn: async ({ participant: { id, ...participant } }) => {
      const { data } = await axios.put(`participant/${encodeURIComponent(id)}`, {
        id,
        ...participant
      });

      onSuccess();
      queryClient.invalidateQueries(`participant/${id}`);

      return data;
    }
  });

  queryClient.setMutationDefaults("deleteParticipant", {
    mutationFn: async ({ id }) => {
      const { data } = await axios.delete(`participant/${encodeURIComponent(id)}`);

      onSuccess();
      queryClient.invalidateQueries(`participant/${id}`);

      return data;
    }
  });

  return queryClient;
};

export default setup;
