import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faCheck } from "@fortawesome/free-solid-svg-icons";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import { useQuery, useMutation } from "react-query";
import collect from "collect.js";
import Loading from "../../components/Loading/Loading";
import useSearchTwitchUsers from "../../hooks/twitch/useSearchTwitchUsers";
import TwitchLogo from "../../assets/twitch-brand-assets/glitch/Purple/TwitchGlitchPurple.svg";

const UsersCreate = () => {
  const {
    isLoading,
    searchUsersAsync,
    twitchUsers,
    searchCursor: twitchUsersTruncated
  } = useSearchTwitchUsers();

  const { data: { users } } = useQuery("user", { placeholderData: {} });
  const { mutateAsync: createAsync } = useMutation("addUser");

  const twitchUserIds = useMemo(() => collect(users).pluck("twitchId"), [users]);
  const [searchString, setSearchString] = useState("");

  const onSearchHandler = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      searchUsersAsync(searchString);
    },
    [searchUsersAsync, searchString]
  );

  const onAddUserHandler = useCallback(
    twitchId => createAsync({ user: { twitchId } }),
    [createAsync]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Allow Twitch Users</h1>
          <hr />
          <Button variant="light" as={Link} to="/users">
            Back to Users
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <Loading forceDisplay={isLoading} />
        <Form onSubmit={onSearchHandler}>
          <Form.Group className="mb-3" controlId="formId">
            <InputGroup className="overflow-hidden">
              <InputGroup.Append className="rounded-left">
                <InputGroup.Text style={{ paddingRight: "30px" }}>
                  <Image
                    src={TwitchLogo}
                    style={{ height: "-webkit-fill-available" }}
                  />
                </InputGroup.Text>
              </InputGroup.Append>
              <Form.Control
                placeholder="Search twitch for an existing user..."
                onChange={({ target }) => setSearchString(target.value)}
              />
              <InputGroup.Append>
                <Button type="submit">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Form>
        {twitchUsersTruncated && (
          <Alert variant="warning">
            Results truncated, please be more specific!
          </Alert>
        )}
        <Table variant="dark" striped borderless responsive hover>
          <tbody>
            {twitchUsers.map(
              ({ id, thumbnailUrl, displayName, isLive, gameName }) => (
                <tr key={id}>
                  <td>
                    <Image src={thumbnailUrl} height={50} />
                    <a
                      className="pl-2 text-secondary"
                      href={`https://www.twitch.tv/${displayName}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {displayName}
                    </a>
                  </td>
                  <td className="text-monospace text-muted">{id}</td>
                  <td className="font-italic">
                    {isLive && `is live with ${gameName}`}
                  </td>
                  <td className="text-right">
                    {twitchUserIds.contains(id) ? (
                      <Button variant="success" disabled>
                        <FontAwesomeIcon icon={faCheck} />
                        <span className="pl-2">Allowed</span>
                      </Button>
                    ) : (
                      <Button onClick={() => onAddUserHandler(id)}>
                        <FontAwesomeIcon icon={faCheck} />
                        <span className="pl-2">Allow</span>
                      </Button>
                    )}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

export default UsersCreate;
