import React, { useMemo } from "react";
import 'chart.js/auto';
import { Chart } from "react-chartjs-2";
import collect from "collect.js";
import randomFlatColors from "random-flat-colors";
import NoData from "../../NoData/NoData";

const BORDER_RADIUS = 256;

const ChartMessageAnswersBar = ({ messages, showNoData }) => {
  const messageAnswerCounts = useMemo(() =>
    messages.map(({ answers, ...message }) =>
      collect(answers).map((_, index) => message[`answerCount${index + 1}`] || 0).sum()
    ),
    [messages]
  );

  const data = useMemo(
    () => ({
      labels: messages.pluck("message").toArray(),
      datasets: [
        {
          label: "Votes",
          backgroundColor: messages.map(() => randomFlatColors()).toArray(),
          borderRadius: { topLeft: BORDER_RADIUS, topRight: BORDER_RADIUS },
          borderWidth: 1,
          data: messageAnswerCounts.toArray(),
        }
      ]
    }),
    [messages, messageAnswerCounts]
  );

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltips: { enabled: false },
      },
      scales: {
        xAxis: {
          stacked: true
        },
        yAxis: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            precision: 0
          }
        }
      }
    }),
    []
  );

  if (messageAnswerCounts.sum() === 0) {
    if (!showNoData) {
      return null;
    }

    return <NoData />;
  }

  return (
    <div style={{ height: "300px" }}>
      <Chart type="bar" data={data} options={options} />
    </div>
  );
};

export default ChartMessageAnswersBar;
