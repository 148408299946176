import React, { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Loading from "../Loading/Loading";
import FormBadge from "../Form/Badge/Badge";
import ParticipantPSNSearch from "./PSN/Search";

const EventsForm = ({ participant, setParticipant, onSubmit, submitText, persisted }) => {
  const isValid = useMemo(
    () => participant.email,
    [participant.email]
  );

  const onSubmitForm = useCallback(
    async formEvent => {
      formEvent.preventDefault();
      formEvent.stopPropagation();

      onSubmit(participant);
    },
    [participant, onSubmit],
  );

  return (
    <>
      <Loading />
      <Form onSubmit={onSubmitForm}>
        <Form.Row>
          <Form.Group as={Col} controlId="participantEmail">
            <Form.Label>
              Participant Email
              <FormBadge required populated={!!participant.email} />
            </Form.Label>
            <Form.Control
              placeholder="Enter Participant Email"
              type="email"
              onChange={({ target }) => setParticipant({ email: target.value })}
              defaultValue={participant.email}
              disabled={persisted}
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="participantPsnUser">
            <Form.Label>
              PSN Username
              <FormBadge populated={!!participant.psnUser} />
            </Form.Label>
            <ParticipantPSNSearch
              selectedPsnUser={participant.psnUser}
              onSelectPsnUser={psnUser => setParticipant({ psnUser })}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col>
            <Button variant="primary" type="submit" disabled={!isValid}>{submitText}</Button>
          </Col>
        </Form.Row>
      </Form>
    </>
  );
};

export default EventsForm;
