import React, { useCallback, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useMutation } from "react-query";
import GroupsForm from "../../components/Groups/Form";

const GroupsCreate = () => {
  const navigate = useNavigate();
  const { mutateAsync: createAsync } = useMutation("addGroup");

  const [group, setGroup] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    {}
  );

  const onSubmit = useCallback(
    async () => {
      await createAsync({ group });

      navigate("/groups");
    },
    [group, createAsync, navigate]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Create Groups</h1>
          <hr />
          <Button variant="light" as={Link} to="/groups">
            Back to Groups
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <GroupsForm group={group} setGroup={setGroup} onSubmit={onSubmit} submitText="Create Group" />
      </Container>
    </>
  );
};

export default GroupsCreate;
