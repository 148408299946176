import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import collect from "collect.js";
import Table from "../../components/Table/Table";
import TableWrapper from "../../components/Table/Wrapper";
import TableSearch from "../../components/Table/Search";
import MessagesChartsBar from "../../components/Messages/Charts/Bar";

const EXPIRED_OPTIONS = collect({
  "Active": false, "Expired": true, "All": "all"
});

const MessagesIndex = () => {
  const [expired, setExpired] = useState(EXPIRED_OPTIONS.keys().first());
  const dataParams = useMemo(() => ({ expired: EXPIRED_OPTIONS.get(expired) }), [expired]);

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Messages</h1>
          <hr />
          <ButtonGroup>
            <Button variant="light" as={Link} to="/messages/create">
              Create New Message
            </Button>
          </ButtonGroup>
        </Container>
      </Jumbotron>
      <Container>
        <TableWrapper
          columns={[
            "message",
            "sendMessageAt",
            "eventName",
            "groupName",
            "lastSentAt",
            "createdAt",
          ]}
          dataKey="message"
          dataParams={dataParams}
          primaryKeyName="id"
        >
          {
            ({ data }) => (
              <>
                <ButtonGroup className="mb-4">
                  {EXPIRED_OPTIONS.keys().map((key, index) =>
                    <Button variant="primary" onClick={() => setExpired(key)} active={key === expired} key={index}>
                      {key}
                    </Button>
                  )}
                </ButtonGroup>
                <TableSearch />
                <MessagesChartsBar messages={data} />
                <Table showCreate showRead showDelete showUpdate />
              </>
            )
          }
        </TableWrapper>
      </Container>
    </>
  );
};

export default MessagesIndex;
