import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import styles from "./Header.module.scss";
import Logo from "../../logo.svg";
import { useAuthentication } from "../../hooks/useAuthentication";
import useStreamerMode from "../../hooks/useStreamerMode";
import CurrentUserBadge from "../CurrentUserBadge/CurrentUserBadge";

const Header = () => {
  const { currentUser } = useAuthentication();
  const { streamerMode } = useStreamerMode();

  if (!currentUser || streamerMode) {
    return <div hidden />;
  }

  const NavLink = ({ children, to }) => (
    <LinkContainer to={to}>
      <Nav.Link className={styles.navLink}>{children}</Nav.Link>
    </LinkContainer>
  );

  const NavDropDownItem = ({ children, to }) => (
    <LinkContainer to={to}>
      <NavDropdown.Item  className={styles.navDropDownItem}>{children}</NavDropdown.Item>
    </LinkContainer>
  );

  return (
    <header className={styles.header}>
      <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
        <LinkContainer to="/">
          <Navbar.Brand href="#">
            <img className={styles.logo} src={Logo} alt="Carper Creative" />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavLink to="users">
              users
            </NavLink>
            <NavLink to="campaigns">
              campaigns
            </NavLink>
            <NavLink to="groups">
              groups
            </NavLink>
            <NavDropdown title="Events" id="nav-dropdown__event">
              <NavDropDownItem to="events">
                events
              </NavDropDownItem>
              <NavDropDownItem to="participants">
                participants
              </NavDropDownItem>
            </NavDropdown>
            <NavLink to="messages">
              messages
            </NavLink>
          </Nav>
          <Nav className="navbar-right">
            <CurrentUserBadge />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
