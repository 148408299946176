import { useMemo } from "react";
import base64 from "base-64";

const getParticipantsUrl = () => {
  if (window.location.hostname === "localhost") {
    return "http://localhost:3100";
  }

  return "https://trophy-case-race-web.pages.dev";
}

const useEventLink = ({ type, name } = {}) => {


  const eventLinkUrl = useMemo(() => {
    if (!type) {
      return undefined;
    }

    if (!name) {
      return false;
    }

    return `${getParticipantsUrl()}/events/${base64.encode(name)}`;
  }, [type, name]);

  return [eventLinkUrl];
};

export default useEventLink;
