import React, { useMemo, useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faPencil,
  faEye,
  faCheck,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import collect from "collect.js";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import BootstrapTable from "react-bootstrap/Table";
import moment from "moment";
import pluralize from "pluralize";
import t from "typy";
import styles from "./Table.module.scss";
import NoData from "../NoData/NoData";
import { useAuthentication } from "../../hooks/useAuthentication";
import DataContext from "./Context";

export const SortableTableHeader = ({ name, onSortData }) => {
  const sanitisedName = useMemo(() => collect(name.split(".")).last(), [name]);

  const displayName = useMemo(
    () =>
      (sanitisedName + (sanitisedName.startsWith("is") ? "?" : ""))
        .replace(/([A-Z])/gu, " $1")
        .replace(/Id$/, "ID"),
    [sanitisedName]
  );

  return (
    <th>
      <Button variant="link" onClick={() => onSortData(sanitisedName)}>
        {displayName}
      </Button>
    </th>
  );
};

const TableCell = ({ name, value }) => {
  if (name.endsWith("At")) {
    return <td>{value && moment(value).format("LLL")}</td>;
  }

  if (Array.isArray(value)) {
    const toPrefix = name.replace("Name", "");

    return (
      <td>
        {value.map(obj => (
          <Button
            className="text-secondary"
            key={obj}
            variant="variant"
            as={Link}
            to={`/${toPrefix}/${obj}`}
          >
            {obj}
          </Button>
        ))}
      </td>
    );
  }

  if (value === true) {
    return (
      <td>
        <FontAwesomeIcon icon={faCheck} />
      </td>
    );
  }

  if (value === false) {
    return (
      <td>
        <FontAwesomeIcon icon={faTimes} />
      </td>
    );
  }

  if (typeof value === "object") {
    return (
      <td className="text-capitalize">{JSON.stringify(value, null, 2)}</td>
    );
  }

  return <td className="text-capitalize">{value && value.toString()}</td>;
};

const Table = ({ showCreate, showUpdate, showRead, showDelete }) => {
  const { currentUser } = useAuthentication();

  const { data, deleteAsync, dataKey, columns, primaryKeyName } = useContext(DataContext) || {};

  const [sortByKey, setSortByKey] = useState("createdAt");
  const [isSortAscending, setIsSortAscending] = useState(true);

  const sortedData = useMemo(
    () => data
      .when(isSortAscending, items => items.sortBy(sortByKey))
      .when(!isSortAscending, items => items.sortByDesc(sortByKey)),
    [data, isSortAscending, sortByKey]
  );

  const sortData = useCallback(
    key => {
      if (sortByKey === key) {
        setIsSortAscending(value => !value);
      }

      setSortByKey(key);
    },
    [sortByKey]
  );

  const canMutate = useCallback(
    value => value && currentUser?.apiUser?.isAdmin,
    [currentUser]
  );

  return (
    <BootstrapTable variant="dark" striped borderless responsive hover>
      <thead className={styles.thead}>
        <tr>
          {columns.map(name => <SortableTableHeader key={name} name={name} onSortData={sortData} />)}
          <th />
        </tr>
      </thead>
      <tbody>
        {sortedData.isEmpty() && (
          <tr>
            <td colSpan={columns.length + 1}>
              <NoData>
                <span className="pr-2">
                  You have no {pluralize(dataKey.replace("_", " "))}!
                </span>
                {canMutate(showCreate) && (
                  <Button
                    variant="primary"
                    as={Link}
                    to={`/${pluralize(dataKey)}/create`}
                    size="lg"
                  >
                    Create some!
                  </Button>
                )}
              </NoData>
            </td>
          </tr>
        )}
        {sortedData.map((row, index) => (
          <tr key={index}>
            {columns.map(name => (
              <TableCell
                key={name}
                name={name}
                value={t(row, name).safeObject}
              />
            ))}
            <td className="text-right">
              <ButtonGroup>
                {showRead && (
                  <Button
                    variant="primary"
                    size="sm"
                    as={Link}
                    to={`${encodeURIComponent(row[primaryKeyName])}`}
                  >
                    <FontAwesomeIcon icon={faEye} />
                    <span className="pl-2">View</span>
                  </Button>
                )}
                {canMutate(showUpdate) && (
                  <Button
                    variant="primary"
                    size="sm"
                    as={Link}
                    to={`${encodeURIComponent(row[primaryKeyName])}/update`}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                    <span className="pl-2">Update</span>
                  </Button>
                )}
                {canMutate(showDelete) && (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => deleteAsync(row)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                    <span className="pl-2">Delete</span>
                  </Button>
                )}
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </tbody>
    </BootstrapTable>
  );
};

export default Table;
