import { useState, useEffect, useCallback } from "react";
import useTwitchApi from "./useTwitchApi";

const useGetTwitchUserVideos = userTwitchId => {
  const { twitchApiClient } = useTwitchApi();

  const [isLoading, setIsLoading] = useState(false);
  const [twitchUserVideos, setTwitchUserVideos] = useState(null);

  const getUserVideosAsync = useCallback(
    async userTwitchId => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      console.debug("Getting twitch user videos by user ID", { userTwitchId });
      const { data: videos } = await twitchApiClient.videos.getVideosByUser(
        userTwitchId,
        { type: 'archive' },
      );
      console.debug("Twitch user videos found!", { videos });

      setTwitchUserVideos(videos);
      setIsLoading(false);
    },
    [twitchApiClient, isLoading, setIsLoading, setTwitchUserVideos]
  );

  useEffect(() => {
    if (isLoading || !userTwitchId) {
      return;
    }

    getUserVideosAsync(userTwitchId);
  }, [isLoading, getUserVideosAsync, userTwitchId]);

  return {
    isLoading,
    getUserVideosAsync,
    twitchUserVideos
  };
};

export default useGetTwitchUserVideos;
