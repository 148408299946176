import { useMemo } from "react";
import { StaticAuthProvider } from "@twurple/auth";
import { ApiClient } from "@twurple/api";
import { useAuthentication } from "../useAuthentication";

const useTwitchApi = () => {
  const { token: accessToken } = useAuthentication();
  const { REACT_APP_TWITCH_CLIENT_ID: clientId } = process.env;

  const twitchApiClient = useMemo(
    () => {
      const authProvider = new StaticAuthProvider(clientId, accessToken);
      return new ApiClient({ authProvider });
    },
    [clientId, accessToken]
  );

  return { twitchApiClient };
};

export default useTwitchApi;
