import React from "react";
import styles from "./Unauthorised.module.scss";

const Unauthorised = ({ className, ...props }) => {
  return (
    <div className={`${styles.wrapper} ${className || ""}`} {...props}>
      <h1 className={styles.title}>Unauthorised</h1>
      <hr />
      <p>
        <span>If you think you should have access please contact </span>
        <a
          href="https://carpercreative.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Carper Creative
        </a>
        <span>.</span>
      </p>
    </div>
  );
};

export default Unauthorised;
