import React, { useCallback, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useMutation } from "react-query";
import EventForm from "../../components/Events/Form";

const EventsCreate = () => {
  const navigate = useNavigate();

  const { mutateAsync: createAsync } = useMutation("addEvent");

  const [event, setEvent] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    { groupNames: [], chatGroups: {} }
  );

  const onSubmit = useCallback(
    async () => {
      await createAsync({ event });

      navigate("/events");
    },
    [event, createAsync, navigate]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Create Event</h1>
          <hr />
          <Button variant="light" as={Link} to="/events">
            Back to Events
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <EventForm event={event} setEvent={setEvent} onSubmit={onSubmit} submitText="Create Event" />
      </Container>
    </>
  );
};

export default EventsCreate;
