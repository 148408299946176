import React, { useMemo, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ReactTwitchEmbedVideo from "react-twitch-embed-video";
import useGetTwitchUsers from "../../hooks/twitch/useGetTwitchUsers";

const TwitchEmbed = ({ twitchUserIds = [] }) => {
  const [selectedTwitchChannel, setSelectedTwitchChannel] = useState(null);
  const { twitchUsers } = useGetTwitchUsers(twitchUserIds);
  const selectedTwitchChannelOrFirstChannel = useMemo(
    () =>
      selectedTwitchChannel ||
      (twitchUsers.length > 0 && twitchUsers[0].displayName) ||
      null,
    [selectedTwitchChannel, twitchUsers]
  );

  if (!selectedTwitchChannelOrFirstChannel) {
    return null;
  }

  return (
    <>
      {twitchUsers.length > 1 && (
        <>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              {selectedTwitchChannelOrFirstChannel}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {twitchUsers.map(({ displayName }) => (
                <Dropdown.Item
                  key={displayName}
                  onSelect={() => setSelectedTwitchChannel(displayName)}
                >
                  {displayName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <hr />
        </>
      )}
      {selectedTwitchChannelOrFirstChannel && (
        <ReactTwitchEmbedVideo
          muted={true}
          theme="dark"
          width="100%"
          channel={selectedTwitchChannelOrFirstChannel}
        />
      )}
    </>
  );
};

export default TwitchEmbed;
