import React, { useCallback, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import { useMutation } from "react-query";
import ParticipantForm from "../../components/Participants/Form";

const ParticipantsCreate = () => {
  const navigate = useNavigate();
  const { mutateAsync: createAsync } = useMutation("addParticipant");

  const [participant, setParticipant] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    {}
  );

  const onSubmit = useCallback(
    async () => {
      await createAsync({ participant });

      navigate("/participants");
    },
    [participant, createAsync, navigate]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Create Participants</h1>
          <hr />
          <Button variant="light" as={Link} to="/participants">
            Back to Participants
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <ParticipantForm
          participant={participant}
          setParticipant={setParticipant}
          onSubmit={onSubmit}
          submitText="Create Participant"
        />
      </Container>
    </>
  );
};

export default ParticipantsCreate;
