import React, { useEffect, useState, useMemo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import yaml from "js-yaml";
import collect from "collect.js";
import styles from "./Index.module.scss";
import docs from "../../assets/docs/commands.yaml";

const DocsIndex = ({ searchString }) => {
  useEffect(() => {
    fetch(docs)
      .then(response => response.text())
      .then(obj => yaml.loadAll(obj, (json) => setDocsJson(json)))
  }, []);

  const [docsJson, setDocsJson] = useState({});

  const docsArray = useMemo(
    () => collect(docsJson)
      .dump()
      .map((obj, name) => [({ name, ...obj })])
      .flatten(1)
      .dump()
      .map((obj) => ({ ...obj, raw: JSON.stringify(obj).toLowerCase() })),
    [docsJson],
  );

  const filteredDocsArray = useMemo(
    () => docsArray
      .filter(({ raw }) => !searchString || raw.includes((searchString || "").toLowerCase()))
      .dump(),
    [docsArray, searchString],
  );

  return (
    <>
      <Row>
        <Col>
          <h1>Chat Commands</h1>
        </Col>
      </Row>
      <Row className={styles.docsWrapper}>
        {filteredDocsArray.map(({ name, description, tags, aliases }, index) => (
          <Col xs={12} key={index} className={styles.docsCard}>
            <h4>{name}</h4>
            <div className="my-2">
              {aliases.map((alias, index) => (
                <Badge key={index} className="mr-1" variant="light">
                  {alias}
                </Badge>
              ))}
            </div>
            <hr className="my-2 bg-light" />
            <div className="my-2">
              <div className="mb-2" dangerouslySetInnerHTML={{ __html: description }}></div>
              <div>
                {tags.map((tag, index) => (
                  <Badge key={index} className="mr-1" variant="dark">
                    {tag}
                  </Badge>
                ))}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default DocsIndex;
