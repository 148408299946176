import React, { useCallback, useMemo, useState, useEffect, useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faStepBackward } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import collect from "collect.js";
import { useQuery } from "react-query";
import DatePicker from "react-datepicker";
import pluralize from "pluralize";
import Loading from "../Loading/Loading";
import MutliSelectButtons from "../Form/MultiSelectButtons/MultiSelectButtons";
import OrderedList from "../Form/OrderedList/OrderedList";
import FormBadge from "../Form/Badge/Badge";

const MESSAGE_TYPE = {
  OneTime: "One Time Message",
  Repeating: "Repeating Message",
  Poll: "Poll",
  Trivia: "Trivia"
};

const getMessageType = ({ id, triviaPoints, answers, repeatEveryMinutes }) => {
  if (!id) {
    return null;
  } else if (!!triviaPoints) {
    return MESSAGE_TYPE.Trivia;
  } else if (!!answers) {
    return MESSAGE_TYPE.Poll;
  } else if (!!repeatEveryMinutes) {
    return MESSAGE_TYPE.Repeating;
  }

  return MESSAGE_TYPE.OneTime;
}

const MessagesForm = ({ message, setMessage, onSubmit, submitText }) => {
  const { data: { events = [] } = {} } = useQuery({ queryKey: "event" });
  const { data: { groups = [] } = {} } = useQuery({ queryKey: "group" });

  const [currentAnswer, setCurrentAnswer] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    {}
  );

  const isNewMessage = useMemo(() => !message.id, [message.id]);
  const [messageType, setMessageType] = useState(getMessageType(message));
  useEffect(() => {
    if (!messageType) {
      setMessageType(getMessageType(message));
    }
  }, [messageType, message]);

  const resetMessageForm = useCallback(() => {
    setMessage({ message: message.message, sendAt: message.sendAt });
    setMessageType(null);
  }, [setMessage, message.message, message.sendAt]);

  const onAddAnswer = useCallback(
    () => {
      setMessage({ answers: [...(message.answers || []), currentAnswer] });
      setCurrentAnswer({ text: "", colour: "#000000" });
    },
    [setMessage, message.answers, currentAnswer, setCurrentAnswer]
  );

  const triviaAnswer = useMemo(() =>
    collect(message.answers || []).pluck("text").get(message.triviaAnswer) || "",
    [message.answers, message.triviaAnswer],
  );
  const setTriviaAnswer = useCallback((answers) => {
    const answer = collect(answers).last();
    const index = collect(message.answers || []).pluck("text").search(answer);

    if (index === false) {
      setMessage({ triviaAnswer: undefined });
    } else {
      setMessage({ triviaAnswer: index });
    }
  }, [message.answers, setMessage]);

  const isValid = useMemo(
    () => {
      if (!message.message || !message.sendMessageAt) {
        return false;
      }

      if (messageType !== MESSAGE_TYPE.OneTime) {
        if (!message.sendMessageUntil || Number(message.repeatEveryMinutes) < 1) {
          return false;
        }
      }

      if ([MESSAGE_TYPE.Poll, MESSAGE_TYPE.Trivia].includes(messageType)) {
        if (Number(message.answers?.length) < 2) {
          return false;
        }
      }

      if (messageType === MESSAGE_TYPE.Trivia) {
        if (!triviaAnswer || Number(message.triviaPoints) < 1) {
          return false;
        }
      }

      return true;
    },
    [
      messageType,
      message.message,
      message.sendMessageAt,
      message.answers?.length,
      message.repeatEveryMinutes,
      message.sendMessageUntil,
      triviaAnswer,
      message.triviaPoints
    ],
  );

  const onSubmitForm = useCallback(
    async formEvent => {
      formEvent.preventDefault();
      formEvent.stopPropagation();

      onSubmit({
        ...message,
        sendMessageUntil: message.repeatEveryMinutes
          ? message.sendMessageUntil
          : undefined
      });
    },
    [message, onSubmit]
  );

  const sendMessageAtDate = useMemo(() => {
    if (!message.sendMessageAt) {
      return null;
    }

    return new Date(message.sendMessageAt);
  }, [message.sendMessageAt]);

  const sendMessageUntilDate = useMemo(() => {
    if (!message.sendMessageUntil) {
      return null;
    }

    return new Date(message.sendMessageUntil);
  }, [message.sendMessageUntil]);

  return (
    <>
      <Loading />
      <Form onSubmit={onSubmitForm}>
        {!messageType && !!isNewMessage ? (
          <Form.Row>
            <Form.Group as={Col} xs={12}>
              <MutliSelectButtons
                name="messageType"
                label={<h2>Message Type</h2>}
                options={collect(MESSAGE_TYPE).values()}
                value={[messageType]}
                setValue={values => setMessageType(collect(values).last())}
              />
            </Form.Group>
          </Form.Row>
        ) : (
          <>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <h2>
                  <span>{messageType}</span>
                  {isNewMessage && (
                    <>
                      <span> | </span>
                      <Button variant="dark" onClick={resetMessageForm}>
                        <FontAwesomeIcon icon={faStepBackward} />
                        <span className="pl-1">Step Back</span>
                      </Button>
                    </>
                  )}
                </h2>
                <hr className="bg-light" />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} md={6} controlId="eventName">
                <MutliSelectButtons
                  name="event"
                  label="Event"
                  options={(events || []).map(({ name }) => name)}
                  value={[message.eventName]}
                  setValue={values =>
                    setMessage({ eventName: collect(values).last() })
                  }
                  optional
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} controlId="groupName">
                <MutliSelectButtons
                  name="groups"
                  label="Group"
                  options={(groups || []).map(({ name }) => name)}
                  value={[message.groupName]}
                  setValue={values =>
                    setMessage({ groupName: collect(values).last() })
                  }
                  optional
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} controlId="messageMessage">
                <Form.Label>
                  <span>Message Shown in Chat</span>
                  <FormBadge populated={!!message.message} required />
                </Form.Label>
                <Form.Control
                  placeholder="enter message"
                  onChange={({ target }) => setMessage({ message: target.value })}
                  defaultValue={message.message}
                  required
                  rows="3"
                />
                <Form.Text className="text-muted">
                  This is the raw message that will be displayed in chat; you can tag people just like in twitch using @name.
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} xs={12} md={6} controlId="sendMessageAt">
                <Form.Label>
                  <span>Send Message At</span>
                  <FormBadge populated={!!message.sendMessageAt} required />
                </Form.Label>
                <DatePicker
                  selected={sendMessageAtDate}
                  onChange={sendMessageAt => setMessage({ sendMessageAt })}
                  showTimeSelect
                  dateFormat="MMMM d, yyyy h:mm aa"
                  required
                />
              </Form.Group>
              {messageType !== MESSAGE_TYPE.OneTime && (
                <>
                  <Form.Group as={Col} xs={12} md={6} controlId="sendMessageUntil">
                    <Form.Label>
                      <span>Send Message Until</span>
                      <FormBadge populated={!!message.sendMessageUntil} required />
                    </Form.Label>
                    <DatePicker
                      selected={sendMessageUntilDate}
                      onChange={sendMessageUntil => setMessage({ sendMessageUntil })}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      required
                    />
                    <Form.Text className="text-muted">
                      The {messageType?.toLowerCase()} will be repeated every {message.repeatEveryMinutes || "few"} {pluralize("minute", message.repeatEveryMinutes)} until this time.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} controlId="repeatEveryMinutes">
                    <Form.Label>
                      <span>Minutes Between Messages</span>
                      <FormBadge populated={Number(message.repeatEveryMinutes) > 0} required />
                    </Form.Label>
                    <Form.Control
                      placeholder="to repeat messages, enter number of minutes between messages"
                      type="number"
                      onChange={({ target }) => setMessage({ repeatEveryMinutes: target.value })}
                      defaultValue={message.repeatEveryMinutes}
                      required
                    />
                    <Form.Text className="text-muted">
                      The {messageType?.toLowerCase()} will be repeated this many minutes until we reach the <i>Send Message Until</i> time.
                    </Form.Text>
                  </Form.Group>
                </>
              )}
            </Form.Row>
            {[MESSAGE_TYPE.Trivia, MESSAGE_TYPE.Poll].includes(messageType) && (
              <Form.Row>
                <Form.Group as={Col} controlId="pollAnswers">
                  <Form.Label>
                    <span>Answers (enter at least two)</span>
                    <FormBadge populated={Number(message.answers?.length) > 1} required />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Enter Answer"
                      value={currentAnswer.text || ""}
                      onChange={({ target }) => setCurrentAnswer({ text: target.value })}
                      style={{ borderBottomLeftRadius: 0 }}
                    />
                    <Form.Control
                      type="color"
                      onChange={({ target }) => setCurrentAnswer({ colour: target.value })}
                      value={currentAnswer.colour || "#000000"}
                    />
                    <InputGroup.Append>
                      <Button
                        onClick={onAddAnswer}
                        disabled={!currentAnswer}
                        style={{ borderBottomRightRadius: 0 }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                  <OrderedList
                    list={message.answers || []}
                    setList={list => setMessage({ answers: list })}
                  >
                    {({ text, colour }, index) => (
                      <>
                        <span className="mr-2 rounded" style={{ padding: "10px", backgroundColor: colour }} />
                        <span className={index === message.triviaAnswer ? 'text-success' : ''} >
                          {index + 1}. {text}
                        </span>
                      </>
                    )}
                  </OrderedList>
                </Form.Group>
              </Form.Row>
            )}
            {messageType === MESSAGE_TYPE.Trivia && (
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="triviaAnswer">
                  <MutliSelectButtons
                    name="answer"
                    label="Trivia Answer"
                    options={collect(message.answers || []).pluck("text")}
                    value={[triviaAnswer]}
                    setValue={setTriviaAnswer}
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} controlId="triviaPoints">
                  <Form.Label>
                    <span>Trivia Points</span>
                    <FormBadge populated={Number(message.triviaPoints) > 0} required />
                  </Form.Label>
                  <Form.Control
                    placeholder={"points awarded to event's winning chat group"}
                    type="number"
                    onChange={({ target }) => setMessage({ triviaPoints: target.value })}
                    defaultValue={message.triviaPoints}
                    required
                  />
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Col>
                <Button variant="primary" type="submit" disabled={!isValid}>
                  {submitText}
                </Button>
              </Col>
            </Form.Row>
          </>
        )}
      </Form>
    </>
  );
};

export default MessagesForm;
