import React, { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useQuery } from "react-query";
import collect from "collect.js";
import Loading from "../../components/Loading/Loading";
import MutliSelectButtons from "../../components/Form/MultiSelectButtons/MultiSelectButtons";

const CampaignsForm = ({ campaign, setCampaign, onSubmit, submitText, persisted }) => {
  const { data } = useQuery(["tiltify", "campaign"]);

  const tiltifyCampaigns = useMemo(() => collect(data?.campaigns || []).dump(), [data?.campaigns]);
  const tiltifyCampaignName = useMemo(() => {
    if (!campaign.tiltifyCampaignId || tiltifyCampaigns.isEmpty()) {
      return "";
    }

    return tiltifyCampaigns.firstWhere("id", "===", campaign.tiltifyCampaignId)?.name;
  }, [tiltifyCampaigns, campaign.tiltifyCampaignId]);
  const setTiltifyCampaignId = useCallback((name) => {
    const tiltifyCampaignId = tiltifyCampaigns.firstWhere("name", "===", name)?.id;

    if (!tiltifyCampaignId && persisted) {
      setCampaign({ tiltifyCampaignId: null });
    } else {
      setCampaign({ tiltifyCampaignId });
    }
  }, [setCampaign, tiltifyCampaigns, persisted]);

  const isValid = useMemo(() => !!campaign.name, [campaign.name]);

  const onSubmitForm = useCallback(
    async event => {
      event.preventDefault();
      event.stopPropagation();

      onSubmit(campaign);
    },
    [campaign, onSubmit]
  );

  return (
    <>
      <Loading />
      <Form onSubmit={onSubmitForm}>
        <Form.Group as={Col} controlId="formId">
          <Form.Label>Campaign Name</Form.Label>
          <Form.Control
            placeholder="Enter Campaign Name"
            onChange={({ target }) => setCampaign({ name: target.value })}
            defaultValue={campaign.name}
            disabled={persisted}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="tiltifyCampaign">
          <MutliSelectButtons
            name="tiltifyCampaign"
            label="Tiltify Campaign"
            options={tiltifyCampaigns.pluck("name")}
            value={[tiltifyCampaignName]}
            setValue={values => setTiltifyCampaignId(collect(values).last())}
            optional
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={!isValid}>
          {submitText}
        </Button>
      </Form>
    </>
  );
};

export default CampaignsForm;
