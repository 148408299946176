import React, { useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faRotate } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import Loading from "../../components/Loading/Loading";
import { useAuthentication } from "../../hooks/useAuthentication";
import useStreamerMode, { StreamerModeWidget, StreamerModeButton } from "../../hooks/useStreamerMode";
import ChartMessageAnswersBar from "../../components/Messages/Charts/Answers/Bar";
import TwitchEmbed from "../../components/Twitch/Embed";

const ViewMessage = () => {
  const { isAdmin } = useAuthentication();
  const { streamerMode, refreshRateMs, fontFamily, roundness, colours } = useStreamerMode();

  const { messageId } = useParams();
  const { data, isLoading: isLoadingPollResults } = useQuery({
    queryKey: ["message", messageId],
    refetchInterval: streamerMode ? refreshRateMs : 60 * 1000,
    placeholderData: {}
  });
  const { isLoading: isLoadingSendMessageNow, mutateAsync: sendMesageNow } =
    useMutation("sendMessageNow");

  const message = useMemo(() => data.message || {}, [data]);
  const group = useMemo(() => data.group || {}, [data]);

  const onSendMessageNow = useCallback(() => {
    sendMesageNow({ id: message.id });
  }, [sendMesageNow, message.id]);

  if (streamerMode) {
    return (
      <>
        <StreamerModeWidget
          showRoundness
          colours={message?.answers}
        />
        <ChartMessageAnswersBar
          message={message}
          style={{ height: "100vh" }}
          fontFamily={fontFamily}
          roundness={roundness}
          colours={colours}
        />
      </>
    );
  }

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Messages / {message ? message.message : messageId}</h1>
          <hr />
          <ButtonGroup>
            <Button variant="light" as={Link} to="/messages">
              Back to Messages
            </Button>
            {isAdmin && (
              <Button variant="warning" onClick={onSendMessageNow}>
                {isLoadingSendMessageNow ? (
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                ) : (
                  <FontAwesomeIcon icon={faPaperPlane} />
                )}
                <span className="pl-1">Send Message Now</span>
              </Button>
            )}
          </ButtonGroup>
          <StreamerModeButton variant="light" className="float-right" />
        </Container>
      </Jumbotron>
      <Container>
        <Loading />
        <Row>
          <Col xs={12} lg={6}>
            <Image src={message.thumbnailUrl} fluid />
            <Table variant="dark" striped borderless responsive>
              <tbody>
                <tr>
                  <td>Message</td>
                  <td>{message.message}</td>
                </tr>
                <tr>
                  <td>Send Message At</td>
                  <td>
                    {message.sendMessageAt &&
                      moment(message.sendMessageAt).format("LLL")}
                  </td>
                </tr>
                <tr>
                  <td>Minutes Between Messages</td>
                  <td>{message.repeatEveryMinutes}</td>
                </tr>
                <tr>
                  <td>Send Message Until</td>
                  <td>
                    {message.sendMessageUntil &&
                      moment(message.sendMessageUntil).format("LLL")}
                  </td>
                </tr>
                <tr>
                  <td>Last Sent At</td>
                  <td>
                    {message.lastSentAt &&
                      moment(message.lastSentAt).format("LLL")}
                  </td>
                </tr>
                <tr>
                  <td>Event</td>
                  <td>
                    <Link className="text-secondary" to={`/events/${message.eventName}`}>
                      {message.eventName}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>Group</td>
                  <td>
                    <Link className="text-secondary" to={`/groups/${message.groupName}`}>
                      {message.groupName}
                    </Link>
                  </td>
                </tr>
                {message.triviaPoints && (
                  <tr>
                    <td>Trivia Points</td>
                    <td>{message.triviaPoints}</td>
                  </tr>
                )}
                <tr>
                  <td>Created At</td>
                  <td>{moment(message.createdAt).format("LLL")}</td>
                </tr>
              </tbody>
            </Table>
            {message.answers && (
              <>
                <hr />
                <h2>
                  <span>Poll Results</span>
                  {isLoadingPollResults && (
                    <span className="pl-2">
                      <FontAwesomeIcon icon={faRotate} spin />
                    </span>
                  )}
                </h2>
                {message.answers[message.triviaAnswer]?.text && (
                  <div>
                    <span>The correct answer is: </span>
                    <span className="text-secondary">
                      {message.answers[message.triviaAnswer].text}
                    </span>
                  </div>
                )}
                <ChartMessageAnswersBar message={message} roundness={256} />
              </>
            )}
          </Col>
          <Col xs={12} lg={6}>
            <TwitchEmbed twitchUserIds={group.witchUserIds || []} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ViewMessage;
