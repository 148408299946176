import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import Loading from "../components/Loading/Loading";

const AuthenticationContext = createContext({ token: null, apiToken: null });

export const useAuthentication = () => {
  const navigate = useNavigate();

  const { setToken, token, apiKey, ...context } = useContext(AuthenticationContext);
  const [enabled, setEnabled] = useState(!!(token || apiKey));

  const { data: currentUser } = useQuery({
    queryKey: "whoami",
    enabled,
    onError: ({ response: { status } }) => {
      if (status === 404) {
        navigate('/401');
      }
    },
  });

  useEffect(() => {
    const interceptor = axios.interceptors.request.use((config) => {
      const authorization = token ? `Bearer ${token}` : apiKey ? `Bearer-Bot ${apiKey}` : null;

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: authorization
        }
      };
    });

    setEnabled(!!(token || apiKey));

    return () => {
      axios.interceptors.request.eject(interceptor);

      setEnabled(false);
    };
  }, [token, apiKey]);

  const isAdmin = useMemo(
    () => currentUser && currentUser.apiUser && currentUser.apiUser.isAdmin,
    [currentUser]
  );

  const twitchId = useMemo(
    () =>
      currentUser && currentUser.apiUser
        ? currentUser.apiUser.twitchId
        : undefined,
    [currentUser]
  );

  return {
    setToken,
    currentUser,
    isAdmin,
    twitchId,
    token,
    apiKey,
    ...context
  };
};

const AuthenticationProvider = ({ children }) => {
  const { pathname, search } = useLocation();

  const [token, setToken] = useState(window.sessionStorage.getItem("token"));
  const setTokenAndSessionStorage = useCallback((value) => {
    setToken(value);

    window.sessionStorage.setItem("token", value);
  }, [setToken]);

  const isPublicRoute = useMemo(
    () => pathname.startsWith("/public"),
    [pathname]
  );

  const apiKey = useMemo(() => new URLSearchParams(search).get('apiKey'), [search]);

  useEffect(() => {
    if (isPublicRoute || token || apiKey) {
      return;
    }

    const url = new URL(
      `${process.env.REACT_APP_API_ROOT || "localhost:3000"}/auth/twitch`
    );
    url.searchParams.set(
      "redirectUri",
      `${window.location.origin}/public/sign_in`
    );
    window.location = url;
  }, [isPublicRoute, token, apiKey]);

  if (!isPublicRoute && !token && !apiKey) {
    return <Loading>Redirecting to twitch.tv...</Loading>;
  }

  return (
    <AuthenticationContext.Provider value={{ token, setToken: setTokenAndSessionStorage, apiKey }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
