import axios from "axios";

const setup = queryClient => {
  const onSuccess = () => {
    queryClient.invalidateQueries("user");
  };

  queryClient.setMutationDefaults("addUser", {
    mutationFn: async ({ user }) => {
      const { data } = await axios.post("user", user);

      onSuccess();

      return data;
    }
  });

  queryClient.setMutationDefaults("updateUser", {
    mutationFn: async ({ user: { twitchId, ...user } }) => {
      const { data } = await axios.put(`user/${twitchId}`, {
        twitchId,
        ...user
      });

      onSuccess();
      queryClient.invalidateQueries(`user/${twitchId}`);

      return data;
    }
  });

  queryClient.setMutationDefaults("updateUserApiToken", {
    mutationFn: async ({ user: { twitchId } }) => {
      const { data } = await axios.put(`user/${twitchId}/generateApiToken`, {});

      onSuccess();
      queryClient.invalidateQueries(`whoami`);

      return data;
    },
    retry: 0
  });

  queryClient.setMutationDefaults("deleteUser", {
    mutationFn: async ({ twitchId }) => {
      const { data } = await axios.delete(`user/${twitchId}`);

      onSuccess();
      queryClient.invalidateQueries(`user/${twitchId}`);

      return data;
    }
  });

  return queryClient;
};

export default setup;
