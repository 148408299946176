import React, { useReducer, useCallback } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import MessagesForm from "../../components/Messages/Form";

const MessagesUpdate = () => {
  const navigate = useNavigate();
  const { messageId } = useParams();

  const { data } = useQuery(
    {
      queryKey: ["message", messageId],
      placeholderData: {},
      onSuccess: (data) => setMessage(data.message)
    }
  );

  const { mutateAsync: updateMessage } =
    useMutation("updateMessage");

  const [message, setMessage] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    data.message || {}
  );

  const onSubmit = useCallback(async (message) => {
    await updateMessage({ message });

    navigate("/messages");
  }, [updateMessage, navigate]);

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Messages / {message ? message.message : messageId}</h1>
          <hr />
          <Button variant="light" as={Link} to="/messages">
            Back to Messages
          </Button>
        </Container>
      </Jumbotron>
      <Container>
        <MessagesForm message={message} setMessage={setMessage} onSubmit={onSubmit} submitText="Update Message" />
      </Container>
    </>
  );
};

export default MessagesUpdate;
