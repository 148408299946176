import { useState, useCallback } from "react";
import useTwitchApi from "./useTwitchApi";

const useSearchTwitchUsers = () => {
  const { twitchApiClient } = useTwitchApi();

  const [isLoading, setIsLoading] = useState(false);
  const [twitchUsers, setTwitchUsers] = useState([]);
  const [searchCursor, setSearchCursor] = useState();

  const searchUsersAsync = useCallback(
    async searchString => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      console.debug("Searching for twitch channels", { searchString });
      const { cursor, data } = await twitchApiClient.search.searchChannels(
        searchString
      );
      console.debug("Twitch channels found!", { cursor, data });

      setTwitchUsers(data);
      setSearchCursor(cursor);
      setIsLoading(false);
    },
    [twitchApiClient, isLoading, setIsLoading, setTwitchUsers, setSearchCursor]
  );

  return {
    isLoading,
    searchUsersAsync,
    twitchUsers,
    searchCursor
  };
};

export default useSearchTwitchUsers;
