import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import collect from "collect.js";

const ChatGroup = ({ avatarUrl, name, points, members }) => {
  const memberKeys = collect(members).keys();

  return (
    <>
      <div>
        <Image src={avatarUrl} height={50} />
        <span className="pl-2">{name}</span>
        {points ? (<span>, {points}pts</span>) : undefined}
        {memberKeys.isNotEmpty() ? (<span>, {memberKeys.count()} members</span>) : undefined}
      </div>
      {
        memberKeys.isNotEmpty() && (
          <>
            <hr />
            <Row className='pl-4'>
              {memberKeys.map(
                (name, index) => (
                  <Col key={index} lg={3} md={4} xs={6}>
                    <a
                      className="text-secondary"
                      href={`https://www.twitch.tv/${name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {name}
                    </a>
                  </Col>
                ),
              )}
            </Row>
          </>
        )
      }
    </>
  );
};

export default ChatGroup;
