import React, { useMemo } from "react";
import collect from "collect.js";
import pluralize from "pluralize";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import useStreamerMode, { StreamerModeWidget } from "../../../../hooks/useStreamerMode";
import styles from "./View.module.scss";

const EventDisplayHorizontal = ({ event, fontFamily, ...props }) => {
  const chatGroups = useMemo(
    () => collect(event.chatGroups || {})
      .map((chatGroup, name) => ({ name, ...chatGroup }))
      .values()
      .dump()
      .toArray(),
    [event.chatGroups]
  );

  const width = useMemo(
    () => `${(1 / (chatGroups.length + 1)) * 100}%`,
    [chatGroups.length]
  );

  return (
    <div {...props} className={styles.eventDisplayHorizontalWrapper}>
      <div className={styles.commandWrapper} style={{ width }}>
        <pre className={styles.commandText} style={{ fontFamily }}>!house</pre>
      </div>
      {chatGroups.map(({ name, avatarUrl, points }) => (
        <div key={name} className={styles.chatGroupWrapper} style={{ width }}>
          <img className={styles.chatGroupImage} alt={name} src={avatarUrl} />
          <span style={{ fontFamily }}>
            {points.toLocaleString() + pluralize('pt', points)}
          </span>
        </div>
      ))}
    </div>
  );
}

const EventChatGroupsView = () => {
  const { name: eventName } = useParams();
  const { streamerMode, refreshRateMs, fontFamily } = useStreamerMode();

  const { data: { event } } = useQuery({
    queryKey: ["event", eventName],
    refetchInterval: streamerMode ? refreshRateMs || undefined : undefined,
    placeholderData: {}
  });

  return (
    <>
      <StreamerModeWidget />
      <EventDisplayHorizontal event={event || {}} fontFamily={fontFamily} />
    </>
  );
};

export default EventChatGroupsView;
