import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { useQuery } from "react-query";
import collect from "collect.js";
import moment from "moment";
import { StreamerModeButton } from "../../../hooks/useStreamerMode";
import EventChatGroupsView from "./ChatGroups/View";
import EventMessagesView from "./Messages/View";
import ChatGroupView from "../../../components/Events/ChatGroup/View";
import NoData from "../../../components/NoData/NoData";
import useEventLink from "../../../hooks/Events/useEventLink";
import Loading from "../../../components/Loading/Loading";

const EventsView = () => {
  const { name: eventName } = useParams();

  const { data: { event } = {} } = useQuery({
    queryKey: ["event", eventName],
  });

  const [eventUrl] = useEventLink(event);

  const chatGroups = useMemo(
    () => collect(event?.chatGroups || {})
      .map((chatGroup, name) => ({ name, ...chatGroup }))
      .values(),
    [event?.chatGroups]
  );

  return (
    <>
      <Jumbotron>
        <Container>
          <h1>Events / {event?.name || eventName}</h1>
          <hr />
          <ButtonGroup>
            <Button variant="light" as={Link} to="/events">
              Back to Events
            </Button>
            {eventUrl && (
              <Button variant="dark" as={Link} to="achievementsOrTrophies">
                Achievements or Trophies
              </Button>
            )}
          </ButtonGroup>
        </Container>
      </Jumbotron>
      <Loading />
      {event && (
        <Container>
          <Row className="mb-4">
            <Col xs={12}>
              <Table variant="dark" striped borderless responsive>
                <tbody>
                  <tr>
                    <td>Campaign</td>
                    <td>
                      <Link className="text-secondary" to={`/campaigns/${encodeURIComponent(event.campaignName)}/update`}>
                        {event.campaignName}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Starts at</td>
                    <td>{moment(event.startsAt).format("LLL")}</td>
                  </tr>
                  <tr>
                    <td>Ends at</td>
                    <td>{moment(event.endsAt).format("LLL")}</td>
                  </tr>
                  <tr>
                    <td>Groups</td>
                    <td>
                      {event.groupNames.map((groupName, index) => (
                        <span key={groupName}>
                          {index > 0 && ", "}
                          <Link
                            className="text-secondary"
                            to={`/groups/${encodeURIComponent(groupName)}/update`}
                          >
                            {groupName}
                          </Link>
                        </span>
                      ))}</td>
                  </tr>
                  {eventUrl && (
                    <tr>
                      <td>Event Link</td>
                      <td>
                      <a
                        className="text-secondary"
                        href={eventUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                          {eventUrl}
                        </a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="w-100">
                <h2 className="d-inline">Trivia Point Widget</h2>
                <StreamerModeButton variant="light" className="float-right" url={`/events/${encodeURIComponent(event?.name)}/chat-groups`} />
              </div>
              <hr className="my-2 bg-light" />
            </Col>
            <Col xs={12}>
              <EventChatGroupsView />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={12}>
              <div className="w-100">
                <h2 className="d-inline">Current Trivia Question Widget</h2>
                <StreamerModeButton variant="light" className="float-right" url={`/events/${encodeURIComponent(event?.name)}/messages`} />
              </div>
              <hr className="my-2 bg-light" />
            </Col>
            <Col xs={12}>
              <EventMessagesView />
            </Col>
          </Row>
          <Row className="pt-4">
            <Col xs={12}>
              <h2>Chat Groups</h2>
              <hr className="my-2 bg-light" />
            </Col>
            {chatGroups.isNotEmpty() ? (
              chatGroups.map((chatGroup, index) => (
                <Col key={index} className="mt-2" xs={12}>
                  <ChatGroupView {...chatGroup} />
                </Col>
              ))
            ) : (
              <Col xs={12}>
                <NoData />
              </Col>
            )}
          </Row>
        </Container>
      )}
    </>
  );
};

export default EventsView;
