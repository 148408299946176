import React, { useCallback, useReducer, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useQuery } from "react-query";
import DatePicker from "react-datepicker";
import collect from "collect.js";
import Loading from "../../components/Loading/Loading";
import FormBadge from "../../components/Form/Badge/Badge";
import OrderedList from "../../components/Form/OrderedList/OrderedList";
import MutliSelectButtons from "../../components/Form/MultiSelectButtons/MultiSelectButtons";
import ButtonToggle from "../../components/Form/ButtonToggle/ButtonToggle";
import ChatGroupView from "./ChatGroup/View";
import useEventLink from "../../hooks/Events/useEventLink";

const EventsForm = ({ event, setEvent, onSubmit, submitText, persisted }) => {
  const { data: { campaigns = [] } = {} } = useQuery({ queryKey: "campaign" });
  const { data: { groups = [] } = {} } = useQuery({ queryKey: "group" });

  const [currentChatGroup, setCurrentChatGroup] = useReducer(
    (accumulator, currentValue) => ({ ...accumulator, ...currentValue }),
    { name: "", avatarUrl: "", points: 0, members: {} },
  );

  const chatGroups = useMemo(
    () => collect(event.chatGroups || {})
      .map((chatGroup, name) => ({ name, ...chatGroup }))
      .values()
      .toArray(),
    [event.chatGroups]
  );

  const setChatGroups = useCallback(
    (chatGroups) => setEvent({
      chatGroups: collect(chatGroups)
        .mapWithKeys(({ name, ...chatGroup }) => [name, chatGroup])
        .dump()
        .all(),
    }),
    [setEvent],
  );

  const onAddChatGroup = useCallback(
    () => {
      const { name, ...chatGroup } = currentChatGroup;

      setEvent({ chatGroups: { ...event.chatGroups, [name]: chatGroup } });
      setCurrentChatGroup({ name: "", avatarUrl: "", points: 0, members: {} });
    },
    [setEvent, event.chatGroups, currentChatGroup, setCurrentChatGroup],
  );

  const onToggleType = useCallback(() => {
    if (event.type === 'trophy-case-race') {
      setEvent({ type: undefined });
    } else {
      setEvent({ type: 'trophy-case-race' });
    }
  }, [setEvent, event.type]);

  const [eventUrl] = useEventLink(event);

  const isValid = useMemo(
    () =>
      event.name &&
      event.campaignName &&
      event.startsAt &&
      event.endsAt &&
      collect(event.chatGroups || {}).keys().count() !== 1,
    [
      event.name,
      event.campaignName,
      event.startsAt,
      event.endsAt,
      event.chatGroups,
    ]
  );

  const onSubmitForm = useCallback(
    async formEvent => {
      formEvent.preventDefault();
      formEvent.stopPropagation();

      onSubmit(event);
    },
    [event, onSubmit]
  );

  const filteredGroups = useMemo(
    () => event.campaignName ?
      groups.filter(({ campaignNames }) => !campaignNames || campaignNames.includes(event.campaignName)) :
      [],
    [event.campaignName, groups],
  );

  const startsAt = useMemo(
    () => event.startsAt ? new Date(event.startsAt) : undefined,
    [event.startsAt]
  );
  const endsAt = useMemo(
    () => event.endsAt ? new Date(event.endsAt) : undefined,
    [event.endsAt]
  );

  return (
    <>
      <Loading />
      <Form onSubmit={onSubmitForm}>
        <Form.Row>
          <Form.Group as={Col} controlId="eventName">
            <Form.Label>
              Event Name
              <FormBadge required />
            </Form.Label>
            <Form.Control
              placeholder="Enter Event Name"
              onChange={({ target }) => setEvent({ name: target.value })}
              defaultValue={event.name}
              disabled={persisted}
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={12} md={6} controlId="campaignName">
            <Form.Label>
              Campaign
              <FormBadge required />
            </Form.Label>
            <Form.Control
              as="select"
              aria-label="Select Campaign"
              onChange={({ target }) =>
                setEvent({
                  campaignName: target.value !== "false" && target.value
                })
              }
              value={event.campaignName}
              disabled={!campaigns || campaigns.length === 0}
              required
            >
              <option value={false}>-- select campaign --</option>
              {(campaigns || []).map(({ name }) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6} controlId="type">
            <Form.Label>
              Trophy Case Race?
              <FormBadge optional />
            </Form.Label>
            <ButtonGroup className="d-block">
              <ButtonToggle
                value={event.type === 'trophy-case-race'}
                onToggle={onToggleType}
              />
              {eventUrl && (
                <Button
                  variant="light"
                  as="a" href={eventUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Invite Link!
                </Button>
              )}
            </ButtonGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} xs={12} md={6} controlId="startsAt">
            <Form.Label>
              Starts At
              <FormBadge required />
            </Form.Label>
            <DatePicker
              selected={startsAt}
              onChange={startsAt => setEvent({ startsAt })}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} md={6} controlId="endsAt">
            <Form.Label>
              Ends At
              <FormBadge required />
            </Form.Label>
            <DatePicker
              selected={endsAt}
              onChange={endsAt => setEvent({ endsAt })}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="groupName">
            <MutliSelectButtons
              name="groups"
              label="Group (filtered by Campaign)"
              options={filteredGroups.map(({ name }) => name)}
              value={event.groupNames}
              setValue={groupNames => setEvent({ groupNames })}
              optional
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="chatGroups">
            <Form.Label>
              <span>Chat Groups (enter at least two)</span>
              <FormBadge />
            </Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Enter Chat Group Name"
                value={currentChatGroup.name}
                onChange={({ target }) => setCurrentChatGroup({ name: target.value })}
                style={{ borderBottomLeftRadius: 0 }}
              />
              <Form.Control
                placeholder="Enter Chat Group Avatar URL"
                value={currentChatGroup.avatarUrl}
                onChange={({ target }) => setCurrentChatGroup({ avatarUrl: target.value })}
                style={{ borderBottomLeftRadius: 0 }}
              />
              <InputGroup.Append>
                <Button
                  onClick={onAddChatGroup}
                  disabled={!currentChatGroup}
                  style={{ borderBottomRightRadius: 0 }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <OrderedList
              list={chatGroups}
              setList={list => setChatGroups(list)}
            >
              {({ ...args }) => <ChatGroupView {...args} />}
            </OrderedList>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Col>
            <Button variant="primary" type="submit" disabled={!isValid}>{submitText}</Button>
          </Col>
        </Form.Row>
      </Form>
    </>
  );
};

export default EventsForm;
