import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";

const OrderedList = ({ list, setList, children }) => {
  const canMoveItemUp = index => index > 0;
  const canMoveItemDown = useCallback(index => index < list.length - 1, [list]);

  const onMoveListItem = useCallback(
    (index, offset) => {
      const listCopy = [...list];

      const swap = listCopy[index];
      listCopy[index] = listCopy[index + offset];
      listCopy[index + offset] = swap;

      setList(listCopy);
    },
    [list, setList]
  );

  const onDeleteListItem = useCallback(
    index => {
      const listCopy = [...list];
      listCopy.splice(index, 1);
      setList(listCopy);
    },
    [list, setList]
  );

  return (
    <Table variant="dark" striped borderless responsive>
      <tbody>
        {list.map((obj, index) => (
          <tr key={index}>
            <td>
              {typeof children === "function" ? children(obj, index) : children}
            </td>
            <td className="text-right">
              <ButtonGroup>
                <Button
                  disabled={!canMoveItemUp(index)}
                  onClick={() => onMoveListItem(index, -1)}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </Button>
                <Button
                  disabled={!canMoveItemDown(index)}
                  onClick={() => onMoveListItem(index, 1)}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </Button>
                <Button
                  variant="danger"
                  onClick={() => onDeleteListItem(index)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default OrderedList;
